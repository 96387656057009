import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Layout, message, Row } from 'antd';

import Steps from './Steps';
import About from './About';
import Work from './Work';
import Achievements from './Achievements';

import { ProfileProps } from '@src/core/props';


const { Content } = Layout;


class Profile extends Component<ProfileProps, {}> {

  componentWillMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps: ProfileProps) {
    if (nextProps.profile.get('error')) {
      message.error(nextProps.profile.get('error'));
      this.props.resetProfileError();
    }
    if (this.props !== nextProps) {
      const step = this.props.auth.get('step');
      const path = this.props.history.location.pathname;
      const onboarded = this.props.auth.get('onboarded');
      if (step === 2 && !path.includes('work') && !onboarded && this.props.history.action === 'POP') {
        this.props.history.push('/profile/work/');
      } else if (step === 3 && !path.includes('achievements') && !onboarded && this.props.history.action === 'POP') {
        this.props.history.push('/profile/achievements/');
      } else if (step === 1 && !path.includes('about') && !onboarded && this.props.history.action === 'POP') {
        this.props.history.push('/profile/about/');
      }
    }
  }

  render() {
    const { auth, location, match, profile } = this.props;
    return (
      <Content style={{ marginTop: '30px' }}>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <Steps location={location} verified={auth.get('verified')} step={auth.get('step')} />
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" style={{ marginTop: '15px', marginBottom: '15px' }}>
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <Switch>
              <Route
                path={`${match.url}/about/`}
                render={() => {
                  return <About
                    addProfileInfo={this.props.addProfileInfo}
                    addSkills={this.props.addSkills}
                    auth={auth}
                    profile={profile}
                    updateStep={this.props.updateStep}
                    resetProfileError={this.props.resetProfileError}
                  />;
                }}
              />
              <Route
                path={`${match.url}/work/`}
                render={() => {
                  return <Work
                    addExperiences={this.props.addExperiences}
                    profile={profile}
                    updateStep={this.props.updateStep}
                  />;
                }}
              />
              <Route
                path={`${match.url}/achievements/`}
                render={() => {
                  return <Achievements
                    addAchievements={this.props.addAchievements}
                    profile={profile}
                    onboardUser={this.props.onboardUser}
                  />;
                }}
              />
              <Redirect to={`${match.url}/about/`} />
            </Switch>
          </Col>
        </Row>
      </Content>
    );
  }
}


export default Profile;
