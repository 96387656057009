import { List, Record } from 'immutable';

import { Profile } from '@src/core/models';


export interface State extends Profile {
  error: Error;
  loading: boolean;
}


export const iPS: State = {
  about: '',
  certifications: List([]),
  city: '',
  country: '',
  educations: List([]),
  eligibilities: List([]),
  error: null,
  events: List([]),
  experiences: List([]),
  id: 0,
  loading: false,
  name: '',
  phone: '',
  picture: '',
  skills: List([]),
  user_id: 0,
  username: '',
  email: undefined
};


export class ProfileState extends Record(iPS) {

  constructor(params: State) {
    super(params);
  }

  get<T extends keyof State>(key: T): State[T] {
    return super.get(key);
  }

  set<T extends keyof State, V extends keyof State>(key: T, value: State[V]) {
    return super.set(key, value);
  }

}
