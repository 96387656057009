import React, { Component } from 'react';
import { Button, Form, Row, Spin } from 'antd';
import { FormEvent } from 'react';

import {
  CertificationFormContainer,
  EducationFormContainer,
  EligibilityFormContainer,
  EventFormContainer
} from '../Form';

import { AchievementsProps } from '@src/core/props';
import { history } from '@src/redux/store';

import { Profile } from '@src/core/models';

import '@src/css/Achievements.less';


const FormItem = Form.Item;


class AchievementsForm extends Component<AchievementsProps, {}> {

  private certificationForm: CertificationFormContainer;
  private educationForm: EducationFormContainer;
  private eligibilityForm: EligibilityFormContainer;
  private eventForm: EventFormContainer;

  componentWillReceiveProps(nextProps: AchievementsProps) {
    if (nextProps.profile.get('educations').size > 0 &&
      nextProps.profile.get('eligibilities').size > 0) {
      this.props.onboardUser();
      history.push('/resume/');
    }
  }

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    let error = false;
    const data: Profile = {};
    this.certificationForm.validateFields((err: Error, values: Profile) => {
      if (!err) {
        if (values.certifications[0].institute_name) {
          data.certifications = values.certifications;
        }
      } else {
        error = true;
      }
    });

    this.educationForm.validateFields((err: Error, values: Profile) => {
      if (!err) {
        data.educations = values.educations;
      } else {
        error = true;
      }
    });

    this.eventForm.validateFields((err: Error, values: Profile) => {
      if (!err) {
        if (values.events[0].institute_name) {
          data.events = values.events;
        }
      } else {
        error = true;
      }
    });

    this.eligibilityForm.validateFields((err: Error, values: Profile) => {
      if (!err) {
        data.eligibilities = values.eligibilities;
      } else {
        error = true;
      }
    });

    if (!error) {
      this.props.addAchievements(data);
    }
  }

  render() {
    if (this.props.profile.get('loading') || (
      this.props.profile.get('educations').size > 0 &&
      this.props.profile.get('eligibilities').size > 0)) {
      return (
        <div className="spinner-loader">
          <Spin size="large" />
        </div>
      );
    } else {
      return (
        <Form onSubmit={this.handleSubmit}>
          <EducationFormContainer mode="add" education={null} ref={(input) => { this.educationForm = input; }} />

          <CertificationFormContainer
            mode="add"
            certification={null}
            ref={(input) => { this.certificationForm = input; }}
          />

          <EventFormContainer mode="add" event={null} ref={(input) => { this.eventForm = input; }} />

          <EligibilityFormContainer
            mode="add"
            eligibilities={[]}
            ref={(input) => { this.eligibilityForm = input; }}
          />

          <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
            <Row type="flex" justify="end">
              <Button type="primary" htmlType="submit" className="btn--submit">
                Save and Finish
              </Button>
            </Row>
          </div>
        </Form>
      );
    }
  }
}


const Achievements = Form.create<{}>()(AchievementsForm);


export default Achievements;
