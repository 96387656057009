import { Action } from 'redux-actions';
import { ActionsObservable, combineEpics, Epic } from 'redux-observable';

// rxjs
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';

import * as ProfileActions from './actions';
import { RootState } from '@src/redux/state';

import {
  addCertifications,
  addEducations,
  addEligibilities,
  addEvents,
  addExperiences,
  addProfileInfo,
  addProjects,
  addSkills,
  deleteCertification,
  deleteEducation,
  deleteEvent,
  deleteExperience,
  deleteProject,
  editCertification,
  editEducation,
  editEvent,
  editExperience,
  editProfileInfo,
  editProject,
  getCertifications,
  getEducations,
  getEligibilities,
  getEvents,
  getExperiences,
  getProfileInfo,
  getResume,
  getSkills
} from '@src/core/services';

import { Certification, Education, Event, Experience, Profile, Project, Skill } from '@src/core/models';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';


const addAchievementsEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_ACHIEVEMENTS)
      .mergeMap((action: Action<Profile>) => {
        let { certifications, educations, eligibilities, events } = action.payload;
        let achievmentObsevable = undefined;
        if (certifications && events) {
          achievmentObsevable = Observable.forkJoin(
            addCertifications({ certifications }),
            addEducations({ educations }),
            addEligibilities({ eligibilities }),
            addEvents({ events }));
        } else if (!certifications && events) {
          achievmentObsevable = Observable.forkJoin(
            addEducations({ educations }),
            addEligibilities({ eligibilities }),
            addEvents({ events }));
        } else if (certifications && !events) {
          achievmentObsevable = Observable.forkJoin(
            addCertifications({ certifications }),
            addEducations({ educations }),
            addEligibilities({ eligibilities }));
        } else {
          achievmentObsevable = Observable.forkJoin(
            addEducations({ educations }),
            addEligibilities({ eligibilities }));
        }

        return achievmentObsevable.map((data) => {
          data.forEach(dataItem => {
            if (dataItem.certifications) {
              certifications = dataItem;
            }

            if (dataItem.educations) {
              educations = dataItem;
            }

            if (dataItem.eligibilities) {
              eligibilities = dataItem;
            }

            if (dataItem.events) {
              events = dataItem;
            }
          });
          return ProfileActions.ProfileSuccess({ certifications, educations, eligibilities, events });
        })
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });

const addEventEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Event | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_EVENTS)
      .switchMap((action) => {
        return addEvents(action.payload)
          .map((data: List<Event>) => ProfileActions.AddEventsSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const addExperiencesEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_EXPERIENCES)
      .switchMap((action) => {
        return addExperiences(action.payload)
          .map((data: Profile) => ProfileActions.ProfileSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const addProjectsEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_PROJECTS)
      .switchMap((action) => {
        return addProjects(action.payload)
          .map((data: List<Project>) => ProfileActions.AddProjectsSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });

const addCertificationsEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_CERTIFICATIONS)
      .switchMap((action) => {
        return addCertifications(action.payload)
          .map((data: List<Certification>) => ProfileActions.AddCertificationsSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });

const addEducationsEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_EDUCATIONS)
      .switchMap((action) => {
        return addEducations(action.payload)
          .map((data: List<Education>) => ProfileActions.AddEducationsSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });

const addProfileInfoEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_PROFILE_INFO)
      .switchMap((action) => {
        return addProfileInfo(action.payload)
          .map((data: Profile) => ProfileActions.ProfileSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const addSkillsEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.ADD_SKILLS)
      .switchMap((action) => {
        return addSkills(action.payload)
          .map((data: Profile) => { return ProfileActions.ProfileSuccess(data); })
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const deleteCertificationEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<number | Error>>) =>
    action$
      .ofType(ProfileActions.DELETE_CERTIFICATION)
      .switchMap((action: Action<number>) => {
        return deleteCertification(action.payload)
          .map(() => ProfileActions.DeleteCertificationSuccess(action.payload))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const deleteEducationEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<number | Error>>) =>
    action$
      .ofType(ProfileActions.DELETE_EDUCATION)
      .switchMap((action: Action<number>) => {
        return deleteEducation(action.payload)
          .map(() => ProfileActions.DeleteEducationSuccess(action.payload))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const deleteEventEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<number | Error>>) =>
    action$
      .ofType(ProfileActions.DELETE_EVENT)
      .switchMap((action: Action<number>) => {
        return deleteEvent(action.payload)
          .map(() => ProfileActions.DeleteEventSuccess(action.payload))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const deleteExperienceEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<number | Error>>) =>
    action$
      .ofType(ProfileActions.DELETE_EXPERIENCE)
      .switchMap((action: Action<number>) => {
        return deleteExperience(action.payload)
          .map(() => ProfileActions.DeleteExperienceSuccess(action.payload))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const deleteProjectEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Project | Error>>) =>
    action$
      .ofType(ProfileActions.DELETE_PROJECT)
      .switchMap((action: Action<Project>) => {
        return deleteProject(action.payload.get('id'))
          .map(() => ProfileActions.DeleteProjectSuccess(action.payload))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editCertificationEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Certification | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_CERTIFICATION)
      .switchMap((action: Action<Certification>) => {
        return editCertification(action.payload.id, action.payload)
          .map((data: Certification) => ProfileActions.EditCertificationSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editEducationEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Education | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_EDUCATION)
      .switchMap((action: Action<Education>) => {
        return editEducation(action.payload.id, action.payload)
          .map((data: Education) => ProfileActions.EditEducationSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editEligibilitiesEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<string[] | Profile | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_ELIGIBILITIES)
      .switchMap((action: Action<string[]>) => {
        return addEligibilities(action.payload)
          .map((data: Profile) => ProfileActions.ProfileSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editEventEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Event | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_EVENT)
      .switchMap((action: Action<Event>) => {
        return editEvent(action.payload.id, action.payload)
          .map((data: Event) => ProfileActions.EditEventSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editExperienceEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Experience | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_EXPERIENCE)
      .switchMap((action: Action<Experience>) => {
        return editExperience(action.payload.id, action.payload)
          .map((data: Experience) => ProfileActions.EditExperienceSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editProfileInfoEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_PROFILE_INFO)
      .switchMap((action: Action<Profile>) => {
        return editProfileInfo(action.payload)
          .map((data: Profile) => ProfileActions.ProfileSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const editProjectEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Project | Error>>) =>
    action$
      .ofType(ProfileActions.EDIT_PROJECT)
      .switchMap((action: Action<Project>) => {
        return editProject(action.payload.id, action.payload)
          .map((data: Project) => ProfileActions.EditProjectSuccess(data))
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


const getProfileEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.GET_PROFILE)
      .mergeMap(() => {
        return Observable.forkJoin(
          getCertifications(),
          getEducations(),
          getEligibilities(),
          getEvents(),
          getExperiences(),
          getProfileInfo(),
          getSkills()
        )
          .map((data) => {
            const [certifications, educations, eligibilities, events, experiences, info, skills] = data;
            return ProfileActions.ProfileSuccess({
              ...certifications,
              ...educations,
              ...eligibilities,
              ...events,
              ...experiences,
              ...info,
              ...skills
            });
          })
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });

const getResumeEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<Profile | Error>>) =>
    action$
      .ofType(ProfileActions.GET_RESUME)
      .switchMap((action) => {
        return getResume(action.payload)
          .map((data) => {
            // const { certifications, educations, eligibilities, events, experiences, info, skills } = data;
            let info = data.profile;
            delete data.profile;
            delete data.projects_without_experience;
            const dataEligibilities = data.eligibilities;
            const eligibilities = dataEligibilities.map((eligibility: { country: string }) => {
              return eligibility.country;
            });
            delete data.eligibilities;
            data.eligibilities = eligibilities;
            return ProfileActions.ProfileSuccess({
              ...data,
              ...info
            });
          })
          .catch((error: Error) => ActionsObservable.of(ProfileActions.ProfileError(error)));
      });


export const epics = combineEpics(
  addAchievementsEpic,
  addExperiencesEpic,
  addProfileInfoEpic,
  addSkillsEpic,
  deleteCertificationEpic,
  deleteEducationEpic,
  deleteEventEpic,
  deleteExperienceEpic,
  deleteProjectEpic,
  editCertificationEpic,
  editEducationEpic,
  editEligibilitiesEpic,
  editEventEpic,
  editExperienceEpic,
  editProfileInfoEpic,
  editProjectEpic,
  getProfileEpic,
  getResumeEpic,
  addEventEpic,
  addCertificationsEpic,
  addEducationsEpic,
  addProjectsEpic
);
