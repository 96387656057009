import { Action } from 'redux-actions';
import { ActionsObservable, combineEpics, Epic } from 'redux-observable';

// rxjs
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';

import * as AuthActions from './actions';
import { history } from '@src/redux/store';
import { RootState } from '@src/redux/state';

import { User } from '@src/core/models';

import {
  forgot, login, onboardUser, resendEmail, reset, setAuthTokenAndEmail, setOnboarded, signup, updateStep, verifyEmail
} from '@src/core/services';


const forgotPassword: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<string | Error>>) =>
    action$
      .ofType(AuthActions.FORGOT_PASSWORD)
      .switchMap((action) => {
        return forgot(action.payload)
          .map((message: string) => AuthActions.ForgotPasswordSuccess(message))
          .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
      });


const loginUser: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<User | Error>>) =>
    action$
      .ofType(AuthActions.LOGIN)
      .switchMap((action) => {
        return login(action.payload)
          .map((data: User) => {
            if (data.verified) {
              setAuthTokenAndEmail(data.token, data.email, data.onboarded);
              if (data.onboarded) {
                history.push('/resume');
              } else {
                history.push('/profile');
              }
              return AuthActions.LoginUserSuccess(data);
            } else {
              history.push('/auth/login');
              delete data.token;
              const Data = Object.assign(data, { error: 'Email Not Verified' });
              return AuthActions.EmailNotVerified(data);
            }
          })
          .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
      });


const onboardUserEpic: Epic<Action<void | {}>, RootState> =
  (action$: ActionsObservable<Action<void | Error>>) =>
    action$
      .ofType(AuthActions.ONBOARD_USER)
      .switchMap(() => {
        return onboardUser({})
          .map((data) => {
            setOnboarded(true);
            return AuthActions.OnboardUserSuccess();
          })
          .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
      });


const resetPassword: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<string | Error>>) =>
    action$
      .ofType(AuthActions.RESET_PASSWORD)
      .switchMap((action) => {
        return reset(action.payload)
          .map((message: string) => AuthActions.ResetPasswordSuccess(message))
          .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
      });

const resendVerificationEmail: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<string | Error>>) =>
    action$
      .ofType(AuthActions.RESEND_VERIFICATION_EMAIL)
      .switchMap((action) => {
        return resendEmail(action.payload)
          .map((message: string) => AuthActions.ResetPasswordSuccess(message))
          .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
      });

const verifyEmailEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<string | Error>>) =>
    action$
      .ofType(AuthActions.VERIFY_EMAIL)
      .switchMap((action) => {
        return verifyEmail(action.payload)
          .map((message: string) => AuthActions.ResetPasswordSuccess(message))
          .catch((error: Error) => {
            return ActionsObservable.of(AuthActions.AuthError({ error: error.errors[0] }));
          });
      });


const signupUser = (action$: ActionsObservable<Action<User | Error>>) => {
  return action$
    .ofType(AuthActions.SIGNUP)
    .switchMap((action) => {
      return signup(action.payload)
        .map((data: User) => {
          // setAuthTokenAndEmail(data.token, data.email, data.onboarded);
          // history.push('/profile/');
          delete data.token;
          const Data =
            Object.assign(data, {
              error: 'An Email with verification link has been sent. Please confirm your Email first!'
            });
          return AuthActions.SignupUserSuccess(Data);
        })
        .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
    });
};


const updateStepEpic: Epic<Action<{}>, RootState> =
  (action$: ActionsObservable<Action<User | Error>>) =>
    action$
      .ofType(AuthActions.UPDATE_STEP)
      .switchMap((action) => {
        return updateStep(action.payload)
          .map((data: User) => AuthActions.UpdateStepSuccess(data))
          .catch((error: Error) => ActionsObservable.of(AuthActions.AuthError(error)));
      });


export const epics = combineEpics(
  forgotPassword,
  loginUser,
  onboardUserEpic,
  resetPassword,
  signupUser,
  updateStepEpic,
  resendVerificationEmail,
  verifyEmailEpic
);
