import React, { Component, FormEvent } from 'react';
import { Col, Form, Input, Row, Tag } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';


const FormItem = Form.Item;


interface EligibilityFormProps extends FormComponentProps {
  eligibilities: string[];
  mode: string;
}

interface EligibilityState {
  countries: string[];
}


class EligibilityForm extends Component<EligibilityFormProps, EligibilityState> {

  constructor(props: EligibilityFormProps) {
    super(props);

    this.state = {
      countries: props.eligibilities
    };
  }

  componentDidMount() {
    const { eligibilities } = this.props;

    if (eligibilities) {
      this.setState({ countries: eligibilities });
      this.props.form.setFieldsValue({ eligibilities });
    }
  }

  handleCountryClose = (country: string) => {
    const countries = this.state.countries.filter((countryItem) => countryItem !== country);
    this.setState({ countries });
    this.props.form.setFieldsValue({
      eligibilities: countries
    });
  }

  handleCountryInputConfirm = (e: FormEvent<{}>) => {
    e.preventDefault();

    const countryValue = e.target.value;
    let countriesList = this.state.countries;

    if (countryValue && countriesList.indexOf(countryValue) === -1) {
      countriesList = [...countriesList, countryValue];
    }

    this.setState({
      countries: countriesList
    });

    this.props.form.setFieldsValue({
      eligibilities: countriesList
    });

    this.props.form.resetFields(['countries']);
  }

  render() {
    const { mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    const { countries } = this.state;

    const countryFormItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
      {mode === 'add' && (
        <h3 className="section--header"> Highlights </h3>
      )}
      <FormItem
        label="Please add the top highlights of your profile. 
        For eg. React Ninja, Amazon, Software Architect, etc.(Add highlights by pressing Enter)"
        className="form-item"
        colon={false}
      >
        {getFieldDecorator('countries', {
          rules: [{
            message: `Please add the top highlights of your profile. 
            For eg. React Ninja, Amazon, Software Architect, etc.`,
            required: this.state.countries.length === 0,
            whitespace: true
          }],
        })(
          <Input
            // placeholder=""
            type="text"
            onBlur={this.handleCountryInputConfirm}
            onPressEnter={this.handleCountryInputConfirm}
          />
        )}
      </FormItem>

      <FormItem className="form-item hidden">
        {getFieldDecorator('eligibilities', {})(
          <Input type="text" />
        )}
      </FormItem>

      <Row type="flex" justify="start">
        {countries.map((country, index) => {
          const tagElem = (
            <Col key={country} style={{ marginRight: '15px', marginTop: '15px' }}>
              <Tag closable={true} onClose={() => this.handleCountryClose(country)}>
                {country}
              </Tag>
            </Col>
          );
          return tagElem;
        })}
      </Row>
    </div>;
  }
}

export default Form.create<EligibilityFormProps>()(EligibilityForm);
