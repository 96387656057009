import React, { Component } from 'react';
import { message, Spin } from 'antd';
import * as H from 'history';
import * as queryString from 'query-string';
import { AuthState } from '@src/redux/modules/auth';
import { history } from '@src/redux/store';

interface VerifyProps {
  auth: AuthState;
  verifyEmail: (token: string) => {};
  location: H.Location;
}

class Verify extends Component<VerifyProps> {

  componentWillMount() {
    const parsed = queryString.parse(this.props.location.search);
    this.props.verifyEmail(parsed.token);
  }

  componentWillReceiveProps(nextProps: VerifyProps) {
    if (nextProps.auth.get('error')) {
      message.error(nextProps.auth.get('error'));
      history.push('/auth/resend');
    }
    if (nextProps.auth.get('message') && this.props.auth.get('message') !== nextProps.auth.get('message')) {
      message.success(nextProps.auth.get('message'));
      history.push('/auth/login');
    }
  }

  render() {
    return (
      <div style={{ position: 'absolute' }}>
        <Spin size="large" />
      </div>
    );
  }
}

export default Verify;
