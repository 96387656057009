import React, { Component, FormEvent } from 'react';
import { Button, Col, Form, Icon, Input, message, Row, Spin, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';

import { Profile, Skill } from '@src/core/models';
import { AboutProps } from '@src/core/props';
import { history } from '@src/redux/store';

import { AboutFormContainer, SkillFormContainer } from '../Form';

import { API_URL } from '@src/core/ajax';
import { getAuthToken, getUserEmail, verifyPhone, verifyUsername } from '@src/core/services';

import '@src/css/About.less';

interface AboutState {
  profilePicture: string;
  fileList: Array<{}>;
  uploading: Boolean;
}

const FormItem = Form.Item;

class AboutForm extends Component<AboutProps, AboutState> {

  private aboutForm: AboutFormContainer;
  private skillForm: SkillFormContainer;

  constructor(props: AboutProps) {
    super(props);

    this.state = {
      profilePicture: null,
      fileList: [],
      uploading: false
    };
  }

  componentWillReceiveProps(nextProps: AboutProps) {
    if (nextProps.profile.get('username')) {
      nextProps.updateStep(2);
      history.push('/profile/work/');
    }
  }

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();

    let about = '';
    let skills: Skill[] = [];

    this.aboutForm.validateFields((err: Error, values: Profile) => {
      if (!err) {
        about = values.about;
      } else {
        return;
      }
    });

    this.skillForm.validateFields((err: Error, values: {}) => {
      if (!err) {
        delete values.skill;

        values.skills.map((skill: {}) => {
          skill.rating = values[`${skill.name.replace(/\s+/g, '-').toLowerCase()}-rating`];
          delete values[`${skill.name.replace(/\s+/g, '-').toLowerCase()}-rating`];
        });

        skills = values.skills;
      } else {
        return;
      }
    });

    this.props.form.validateFields((err, values) => {
      if (!err && skills.length && about) {
        values.about = about;
        values.skills = skills;

        console.log('Received values of form: ', values);

        this.props.addProfileInfo(values);
        this.props.addSkills(skills);
      }
    });
  }

  validateUsername(rule: {}, value: string, callback: (err?: {}) => {}) {
    // this.props.verifyUsername(value);
    if (value) {
      if (value.length >= 3) {
        verifyUsername({ username: value.trim() }).subscribe(
          (data: {}) => callback(),
          (err: {}) => callback(err.error),
          () => console.log('Response has come')
        );
      } else {
        callback('Username is too short (minimum is 3 characters)');
      }

    } else {
      callback();
    }
  }

  validatePhonenumber(rule: {}, value: string, callback: (err?: {}) => {}) {
    if (value) {
      // let phoneNumber = parseInt(value, 10);
      if (isNaN(+value) || value.length < 10) {
        callback('Please enter a valid phone number!');
      } else {
        verifyPhone({ phone: value.trim() }).subscribe(
          (data: {}) => callback(),
          (err: {}) => callback(err.error),
          () => console.log('Response has come')
        );
      }
    } else {
      callback();
    }
  }

  handleChange(info: UploadChangeParam) {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.status !== 'uploading') {
        if (file.status === 'error' || file.response.error) {
          file.status = 'error';
          let errMsg = info.file.response ? info.file.response.error : 'Error while uploading the image!';
          message.error(errMsg);
        } else {
          this.setState({
            profilePicture: file.response.picture
          });
        }
        this.setState({
          uploading: false
        });
      }

      if (String(file.status) === 'uploading') {
        this.setState({
          uploading: true
        });
      }
      return file;
    });

    this.setState({
      fileList: fileList
    });
  }

  handelRemove() {
    this.setState({
      profilePicture: null,
      fileList: []
    });
    return true;
  }

  render() {
    const imageUrl = this.state.profilePicture;
    const { getFieldDecorator } = this.props.form;
    const photoProps = {
      accept: 'image/*',
      action: `${API_URL}profiles/upload`,
      headers: {
        'X-User-Email': getUserEmail(),
        'X-User-Token': getAuthToken(),
      },
      name: 'profile[picture]',
      onChange: this.handleChange.bind(this),
      onRemove: this.handelRemove.bind(this),
      // listType: 'picture'
    };
    if (this.props.profile.get('loading')) {
      return (
        <div className="spinner-loader">
          <Spin size="large" />
        </div>
      );
    } else {
      return (
        <Form onSubmit={this.handleSubmit} style={{ backgroundColor: 'white', padding: '30px' }}>
          <Row gutter={30}>
            <Col span={24}>
              <FormItem label="Profile Photo" className="form-item form-item--image" colon={false}>
                <div className="dropbox">
                  <Upload.Dragger
                    {...photoProps}
                    fileList={this.state.fileList}
                  >
                    {
                      this.state.profilePicture ?
                        <img className="profile-picture" src={this.state.profilePicture} /> :
                        (
                          <p className="ant-upload-drag-icon">
                            <Icon type={this.state.uploading ? 'loading' : 'camera'} />
                          </p>
                        )
                    }
                  </Upload.Dragger>
                </div>
                <p className="form-item--help-text">Add Your Profile Photo</p>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Your Name" className="form-item" colon={false}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please enter your name!', whitespace: true },
                  { min: 3, message: 'Name is too short (minimum is 3 characters)' }],
                })(
                  <Input
                  // placeholder="Eg. Jonathan Doe"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Email Address" className="form-item" colon={false}>
                <Input
                  // placeholder="Email Address"
                  type="email"
                  value={this.props.auth.get('email')}
                  disabled={true}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Phone Number" className="form-item" colon={false}>
                {getFieldDecorator('phone', {
                  rules: [{
                    required: true, message: 'Please enter the phone number!', whitespace: false
                  }, {
                    validator: this.validatePhonenumber.bind(this)
                  }],
                })(
                  <Input
                  // placeholder="Eg. 9999999999"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="Username" className="form-item" colon={false}>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: 'Please enter the username!', whitespace: false }, {
                    validator: this.validateUsername.bind(this),
                  }],
                })(
                  <Input
                  // placeholder="Eg. jonathan"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="City" className="form-item" colon={false}>
                {getFieldDecorator('city', {
                  rules: [{ required: true, message: 'Please enter the city!', whitespace: true },
                  { min: 3, message: 'City is too short (minimum is 3 characters)' }],
                })(
                  <Input
                  // placeholder="Eg. Chicago City"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Country" className="form-item" colon={false}>
                {getFieldDecorator('country', {
                  rules: [{ required: true, message: 'Please enter the country!', whitespace: true },
                  { min: 3, message: 'Country is too short (minimum is 3 characters)' }],
                })(
                  <Input
                  // placeholder="Eg. United States"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <SkillFormContainer skills={[]} ref={(input) => { this.skillForm = input; }} />
          </Row>
          <Row>
            <AboutFormContainer about="" ref={(input) => { this.aboutForm = input; }} />
          </Row>
          <Row type="flex" justify="end">
            <FormItem>
              <Button type="primary" htmlType="submit" className="btn--submit">
                Save and Proceed To Next
            </Button>
            </FormItem>
          </Row>
        </Form>
      );
    }
  }
}


const About = Form.create<{}>()(AboutForm);


export default About;
