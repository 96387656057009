import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';


const FormItem = Form.Item;
const { TextArea } = Input;


interface AboutFormProps extends FormComponentProps {
  about: string;
}


class AboutForm extends Component<AboutFormProps> {

  componentDidMount() {
    const { about } = this.props;

    if (about) {
      this.props.form.setFieldsValue({ about });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return <FormItem label="Some Words About You (Max 500 Characters)" className="form-item" colon={false}>
      {getFieldDecorator('about', {
        rules: [
          { required: true, message: 'Please enter your brief description!', whitespace: true },
          { max: 500, message: 'Max 500 Characters are allowed!' },
          { min: 100, message: 'About should be at least 100 characters!' },
        ],
      })(
        <TextArea 
        // placeholder="Please enter a brief description"
        />
      )}
    </FormItem>;
  }
}

export default Form.create<AboutFormProps>()(AboutForm);
