import { Action, combineActions, handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';

import * as ProfileActions from './actions';
import { iPS, ProfileState, State } from './state';
import { Certification, Education, Event, Experience, Project } from '@src/core/models';
import { fileToObject } from 'antd/lib/upload/utils';


export const profileReducer = handleActions<ProfileState, State>(
  {

    [combineActions(
      ProfileActions.ADD_ACHIEVEMENTS,
      ProfileActions.ADD_EVENTS,
      ProfileActions.ADD_PROJECTS,
      ProfileActions.ADD_CERTIFICATIONS,
      ProfileActions.ADD_EXPERIENCES,
      ProfileActions.ADD_PROFILE_INFO,
      ProfileActions.ADD_SKILLS,
      ProfileActions.DELETE_CERTIFICATION,
      ProfileActions.DELETE_EDUCATION,
      ProfileActions.DELETE_EVENT,
      ProfileActions.DELETE_EXPERIENCE,
      ProfileActions.DELETE_PROJECT,
      ProfileActions.EDIT_CERTIFICATION,
      ProfileActions.EDIT_EDUCATION,
      ProfileActions.EDIT_ELIGIBILITIES,
      ProfileActions.EDIT_EVENT,
      ProfileActions.EDIT_EXPERIENCE,
      ProfileActions.EDIT_PROFILE_INFO,
      ProfileActions.GET_PROFILE,
    )]:
      (state: ProfileState, action: Action<State>) => state.merge({ error: null, loading: true }),

    [ProfileActions.DELETE_CERTIFICATION_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('certifications', (certifications) => {
        return certifications.filter((c: Certification) => c.get('id') !== action.payload.id);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.DELETE_EDUCATION_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('educations', (educations) => {
        return educations.filter((c: Education) => c.get('id') !== action.payload.id);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.DELETE_EVENT_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('events', (events) => {
        return events.filter((c: Event) => c.get('id') !== action.payload.id);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.DELETE_EXPERIENCE_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('experiences', (experiences) => {
        return experiences.filter((c: Experience) => c.get('id') !== action.payload.id);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.DELETE_PROJECT_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('experiences', (experiences) => {
        const eId = experiences.findIndex((c: Experience) => c.get('id') === action.payload.get('experience_id'));
        const experience = experiences.get(eId).update('projects', (projects: List<Project>) => {
          return projects.filter((p: Project) => p.get('id') !== action.payload.get('id'));
        });
        return experiences.set(eId, experience);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.EDIT_CERTIFICATION_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('certifications', (certifications) => {
        const i = certifications.findIndex((c: Certification) => c.get('id') === action.payload.id);
        return certifications.set(i, fromJS(action.payload));
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.EDIT_EDUCATION_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('educations', (educations) => {
        const i = educations.findIndex((c: Education) => c.get('id') === action.payload.id);
        return educations.set(i, fromJS(action.payload));
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.EDIT_EVENT_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('events', (events) => {
        const i = events.findIndex((c: Event) => c.get('id') === action.payload.id);
        return events.set(i, fromJS(action.payload));
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.ADD_EVENTS_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      let newEvents = action.payload.events.map(event => Map(event));
      state = state.update('events', (events) => {
        return events.concat(newEvents);
      });
      return state.merge({ loading: false });
    },


    [ProfileActions.ADD_PROJECTS_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      let newProjects = action.payload.projects.map(p => Map(p));
      let expId = newProjects[0].get('experience_id');
      state = state.update('experiences', (experiences) => {
        const eId = experiences.findIndex((c: Experience) => c.get('id') === expId);
        const experience = experiences.get(eId).update('projects', (projects: List<Project>) => {
          return projects.concat(newProjects);
        });
        return experiences.set(eId, experience);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.ADD_CERTIFICATIONS_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      let newCertifications = action.payload.certifications.map(certification => Map(certification));
      state = state.update('certifications', (certifications) => {
        return certifications.concat(newCertifications);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.ADD_EDUCATIONS_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      let newEducations = action.payload.educations.map(ed => Map(ed));
      state = state.update('educations', (educations) => {
        return educations.concat(newEducations);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.EDIT_EXPERIENCE_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('experiences', (experiences) => {
        const i = experiences.findIndex((c: Experience) => c.get('id') === action.payload.id);
        let experience = Map(action.payload);
        experience = experience.set('projects', fromJS(experiences.get(i).get('projects')));
        return experiences.set(i, fromJS(experience));
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.EDIT_PROJECT_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('experiences', (experiences) => {
        const eId = experiences.findIndex((c: Experience) => c.get('id') === action.payload.experience_id);
        const experience = experiences.get(eId).update('projects', (projects: List<Project>) => {
          const pId = projects.findIndex((p: Project) => p.get('id') === action.payload.id);
          return projects.set(pId, fromJS(action.payload));
        });
        return experiences.set(eId, experience);
      });
      return state.merge({ loading: false });
    },

    [ProfileActions.ADD_SKILL_SUCCESS]: (state: ProfileState, action: Action<State>) => {
      state = state.update('skills', (skills) => {
        return skills.filter((c: Experience) => false);

      });
      // ...action.payload
      const finalState = state.merge(action.payload.skills, { loading: false });
      return finalState;
    },

    [ProfileActions.RESET_PROFILE_ERROR]: (state: ProfileState, action: Action<State>) => {
      return state.merge({ error: null });
    },

    [combineActions(ProfileActions.PROFILE_SUCCESS, ProfileActions.PROFILE_ERROR)]:
      (state: ProfileState, action: Action<State>) => {
        const finalState = state.merge({ ...action.payload, loading: false });
        return finalState;
      },

    [ProfileActions.RESET_PROFILE]:
      (state: ProfileState, action: Action<State>) => new ProfileState(iPS),

  },
  new ProfileState(iPS)
);
