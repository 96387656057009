import * as React from 'react';

import '@src/css/Landing.less';

class Landing extends React.Component {
  render() {
    return (
      <div className="landing text--light">
        <h1 className="text--light">
          Take the first step towards showcasing your skills and work the right
          way!
        </h1>
        {/* <ul>
          <li>Highlights your projects and achievements</li>
          <li>Show your true value to your potential recruiters</li>
          <li>One-Click share and export</li>
        </ul> */}
      </div>
    );
  }
}

export default Landing;
