import { Action, combineActions, handleActions } from 'redux-actions';

import * as AuthActions from './actions';
import { AuthState, iAS, State } from './state';
import { getAuthToken, getIsOnboarded, getUserEmail } from '@src/core/services';


export const authReducer = handleActions<AuthState, State>(
  {

    [AuthActions.GET_AUTH_TOKEN_AND_EMAIL]: (state: AuthState, action: Action<State>) => {
      const token = getAuthToken();
      const email = getUserEmail();
      const onboarded = getIsOnboarded();
      return state.merge({ email, token, onboarded });
    },

    [AuthActions.LOGOUT]: (state: AuthState, action: Action<State>) => {
      return new AuthState(iAS);
    },

    [AuthActions.ONBOARD_USER_SUCCESS]: (state: AuthState, action: Action<State>) => {
      return state.merge({ onboarded: true });
    },

    [AuthActions.RESET_AUTH_ERROR]: (state: AuthState, action: Action<State>) => {
      return state.merge({ error: null });
    },

    [combineActions(AuthActions.LOGIN, AuthActions.SIGNUP, AuthActions.FORGOT_PASSWORD, AuthActions.ResetPassword)]:
      (state: AuthState, action: Action<State>) => state.merge({ error: null, loading: true }),

    [combineActions(AuthActions.LOGIN_SUCCESS, AuthActions.SIGNUP_SUCCESS,
      AuthActions.UPDATE_STEP_SUCCESS, AuthActions.AUTH_ERROR, AuthActions.FORGOT_PASSWORD_SUCCESS,
      AuthActions.RESET_PASSWORD_SUCCESS, AuthActions.EMAIL_NOT_VERIFIED)]:
      (state: AuthState, action: Action<State>) => {
        return state.merge({ ...action.payload, loading: false });
      },

  },
  new AuthState(iAS)
);
