import { connect } from 'react-redux';

import { RootState } from '@src/redux/state';

import Resume from '@src/views/components/Resume/Main';
import * as ProfileActions from '@src/redux/modules/profile/actions';
import { ResumeProps } from '@src/core/props';


const mapStateToProps = (state: RootState, ownProps: ResumeProps) => {
  return { ...state };
};


export default connect<{}>(mapStateToProps, {
  addExperiences: ProfileActions.AddExperiences,
  addProjects: ProfileActions.AddProjects,
  addEvents: ProfileActions.AddEvents,
  addSkills: ProfileActions.AddSkills,
  addCertifications: ProfileActions.AddCertifications,
  addEducations: ProfileActions.AddEducations,
  deleteCertification: ProfileActions.DeleteCertification,
  deleteEducation: ProfileActions.DeleteEducation,
  deleteEvent: ProfileActions.DeleteEvent,
  deleteExperience: ProfileActions.DeleteExperience,
  deleteProject: ProfileActions.DeleteProject,
  editCertification: ProfileActions.EditCertification,
  editEducation: ProfileActions.EditEducation,
  editEligibilities: ProfileActions.EditEligibilities,
  editEvent: ProfileActions.EditEvent,
  editExperience: ProfileActions.EditExperience,
  editProfileInfo: ProfileActions.EditProfileInfo,
  editProject: ProfileActions.EditProject,
  getProfile: ProfileActions.GetProfile,
  getResume: ProfileActions.GetResume
})(Resume);
