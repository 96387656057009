import React, { Component } from 'react';
import { Button, Form, Icon, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FormEvent } from 'react';
import * as H from 'history';
import * as queryString from 'query-string';
import { passwordValidations } from '@src/core/validations';
import { AuthState } from '@src/redux/modules/auth';
import { Link } from 'react-router-dom';
import { history } from '@src/redux/store';

const FormItem = Form.Item;


interface ResetProps {
  auth: AuthState;
  reset: (password: string, token: string) => {};
  location: H.Location;
}


class ResetForm extends Component<ResetProps & FormComponentProps> {
  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        const parsed = queryString.parse(this.props.location.search);
        this.props.reset(this.props.form.getFieldValue('password'), parsed.token);
      }
    });
  }

  componentWillReceiveProps(nextProps: ResetProps) {
    if (nextProps.auth.get('error')) {
      message.error(nextProps.auth.get('error'));
      // this.props.resetAuthError();
    }
    if (nextProps.auth.get('message') && this.props.auth.get('message') !== nextProps.auth.get('message')) {
      message.success(nextProps.auth.get('message'));
      history.push('/auth/login');
      // this.props.resetAuthError();
    }
  }

  compareToFirstPassword = (rule: {}, value: string, callback: (err?: {}) => {}) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback(`Passwords that you entered don't match !`);
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const loading = this.props.auth.get('loading');
    return (
      <Form onSubmit={this.handleSubmit} className="form--auth form--auth__login">
        <h3 className="text--center">Reset Password</h3>
        <FormItem label="Password">
          {getFieldDecorator('password', passwordValidations)(
            <Input
              placeholder="Password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
            />
          )}
        </FormItem>
        <FormItem label="Confirm Password">
          {getFieldDecorator('confirmPassword', {
            rules: [{ required: true, message: 'Please re-enter your new password to confirm' }, {
              validator: this.compareToFirstPassword
            }],
          })(
            <Input
              placeholder="Confirm Password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="btn--auth" loading={loading}>
            Reset Password
          </Button>
          <Link to="/auth/login">Sign In</Link>
        </FormItem>
      </Form>
    );
  }
}

const Reset = Form.create<ResetProps>()(ResetForm);


export default Reset;
