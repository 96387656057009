import React, { Component, Fragment } from 'react';
import {
  Button,
  Col,
  Layout,
  message,
  Modal,
  Row,
  Slider,
  Tag,
  Upload
} from 'antd';
import { ResumeProps } from '@src/core/props';
import { Profile, Project } from '@src/core/models';
import { getAuthToken, getUserEmail, verifyUsername } from '@src/core/services';
import { API_URL } from '@src/core/ajax';
import EditForm from '../Form/Edit';
import HireMeForm from '../Form/HireMeForm';
import moment from 'moment';
import '@src/css/Resume.less';

import awards from '@src/img/icon/resume/awards.svg';
import building from '@src/img/icon/resume/building.svg';
import certifications from '@src/img/icon/resume/certifications.svg';
import date from '@src/img/icon/resume/date.svg';
import education from '@src/img/icon/resume/education.svg';
import email from '@src/img/icon/resume/email.svg';
import locationIcon from '@src/img/icon/resume/location.svg';
import telephone from '@src/img/icon/resume/telephone.svg';
import work from '@src/img/icon/resume/work.svg';
import user from '@src/img/icon/resume/user.svg';
import userBlack from '@src/img/icon/resume/user-no-avatar.svg';
import edit from '@src/img/icon/edit.svg';

const { Content } = Layout;

interface ResumeState {
  editType: keyof Profile | 'project';
  isDelete: boolean;
  modalData: Profile[keyof Profile] | Project;
  modalMode: string;
  title: string;
  visible: boolean;
  profilePicture: string;
  fileList: Array<{}>;
  uploading: boolean;
  isEditable: boolean;
  isProfileVisible: boolean;
}

class Resume extends Component<ResumeProps, ResumeState> {
  constructor(props: ResumeProps) {
    super(props);

    this.state = {
      isEditable: false,
      isProfileVisible: false,
      editType: null,
      isDelete: false,
      modalData: null,
      modalMode: '',
      title: '',
      visible: false,
      profilePicture: null,
      fileList: [],
      uploading: false
    };
  }

  componentWillMount() {
    const { path } = this.props.match;
    if (path.includes('pvt') || !this.props.match.params.username) {
      this.setState({
        isProfileVisible: true
      });
    }
    if (this.props.match.params.username) {
      let username = this.props.match.params.username;
      this.props.getResume(username);
    } else {
      this.props.getProfile();
    }
  }

  componentWillReceiveProps(nextProps: ResumeProps) {
    if (!nextProps.profile.get('loading') && !nextProps.profile.get('error')) {
      this.setState({
        visible: false
      });

      if (this.props !== nextProps) {
        const step = this.props.auth.get('step');
        const path = this.props.history.location.pathname;
        const onboarded = this.props.auth.get('onboarded');
        if (
          step === 3 &&
          !path.includes('resume') &&
          onboarded &&
          this.props.history.action === 'POP'
        ) {
          this.props.history.push('/resume/');
        }
      }
    }

    if (
      nextProps.match.params.username &&
      this.props.match.params.username !== nextProps.match.params.username
    ) {
      let username = nextProps.match.params.username;
      this.props.getResume(username);
    } else if (
      !this.props.match.params.username &&
      !nextProps.match.params.username
    ) {
      this.setState({
        isEditable: true
      });
    }

    if (nextProps.profile.get('error')) {
      message.error(nextProps.profile.get('error'));
    }
  }

  getSkillRate = (rating: number) => {
    if (rating === 3) {
      return 'Intermediate';
    }
    if (rating === 4) {
      return 'Advance';
    }
    if (rating === 5) {
      return 'Expert';
    }
    return 'Beginner';
  }

  showHireModal() {
    this.setState({
      visible: true
    });
  }

  showModal = (
    editType: keyof Profile | 'project',
    modalMode: string,
    id: number,
    projectId?: number
  ) => {
    let modalData =
      editType !== 'project'
        ? this.props.profile.get(editType)
        : this.props.profile.get('experiences');
    let title;
    let isDelete;
    if (
      modalMode !== 'edit' ||
      editType === 'about' ||
      editType === 'eligibilities' ||
      editType === 'skills'
    ) {
      isDelete = false;
    } else if (
      editType === 'project' ||
      (editType !== 'project' && modalData.size >= 1)
    ) {
      isDelete = true;
    }

    if (id) {
      modalData = modalData.find((d: {}) => d.get('id') === id);
    }

    if (projectId) {
      modalData = modalData
        .get('projects')
        .find((d: {}) => d.get('id') === projectId);
    }

    if (modalMode === 'create' && editType !== 'project') {
      modalData = null;
    }

    if (editType === 'about') {
      title = 'Profile Overview';
    } else if (editType === 'educations') {
      title = 'Education';
    } else if (editType === 'certifications') {
      title = 'Certification';
    } else if (editType === 'events') {
      title = 'Event';
    } else if (editType === 'eligibilities') {
      title = 'Highlights';
    } else if (editType === 'experiences') {
      title = 'Experience';
    } else if (editType === 'project') {
      title = 'Project';
    } else if (editType === 'skills') {
      title = 'Skills & Expertise';
    }

    this.setState({
      editType,
      isDelete,
      modalData,
      modalMode,
      title,
      visible: true
    });
  }

  handleChange(info: UploadChangeParam) {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.status !== 'uploading') {
        if (file.status === 'error' || file.response.error) {
          file.status = 'error';
          let errMsg = info.file.response
            ? info.file.response.error
            : 'Error while uploading the image!';
          message.error(errMsg);
        } else {
          this.setState({
            profilePicture: file.response.picture
          });
        }
        this.setState({
          uploading: false
        });
      }

      if (String(file.status) === 'uploading') {
        this.setState({
          uploading: true
        });
      }
      return file;
    });

    this.setState({
      fileList: fileList
    });
  }

  handelRemove() {
    this.setState({
      profilePicture: null,
      fileList: []
    });
    return true;
  }

  handleCancel = () => {
    this.setState({ title: '', visible: false });
  }

  render() {
    const { auth, profile } = this.props;
    const { isEditable, profilePicture, isProfileVisible } = this.state;
    const profileEmail = this.props.profile.get('email');
    const photoProps = {
      accept: 'image/*',
      action: `${API_URL}profiles/upload`,
      headers: {
        'X-User-Email': getUserEmail(),
        'X-User-Token': getAuthToken()
      },
      name: 'profile[picture]',
      onChange: this.handleChange.bind(this),
      onRemove: this.handelRemove.bind(this)
    };

    const skils = profile
      .get('skills')
      .valueSeq()
      .map(skill => {
        const rating = this.getSkillRate(skill.get('rating'));
        return {
          name: skill.get('name'),
          rating: skill.get('rating'),
          rate: this.getSkillRate(skill.get('rating'))
        };
      })
      .toArray();
    return (
      <Content className="resume-main-container">
        <Row type="flex" gutter={{ xs: 8, sm: 16, md: 24}}>
          <Col xs={24} md={7} span={7}>
            <div className="resume--item">
              <div
                className="resume--item--container resume--item--header"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div
                  className="profile-pic-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {isProfileVisible ? (
                    <Fragment>
                      <img
                        src={
                          profilePicture
                            ? profilePicture
                            : profile.get('picture')
                        }
                        className="resume--item--profile"
                      />
                      <span
                        className="resume--item--content"
                        style={{ margin: 0 }}
                      >
                        {profile.get('name')}
                      </span>
                    </Fragment>
                  ) : (
                    <img src={userBlack} className="resume--item--profile" />
                  )}
                </div>
                {isEditable && (
                  <Upload
                    className="edit-profile-pic"
                    {...photoProps}
                    fileList={this.state.fileList}
                  >
                    <img
                      src={edit}
                      className="resume--item--button resume--item--icon"
                    />
                  </Upload>
                )}
              </div>
              {isEditable ? (
                <div className="resume--item--container">
                  <p>
                    <img src={email} />
                    <span className="resume--item--content">
                      {isEditable ? auth.get('email') : profileEmail}
                    </span>
                  </p>
                  <p>
                    <img src={telephone} />
                    <span className="resume--item--content">
                      {profile.get('phone')}
                    </span>
                  </p>
                  <p>
                    <img src={locationIcon} />
                    <span className="resume--item--content">
                      {profile.get('city') + ', ' + profile.get('country')}
                    </span>
                  </p>
                </div>
              ) : (
                <div className="resume--item--container content-center">
                  <Button
                    type="primary"
                    className="btn-header"
                    onClick={() => this.showHireModal()}
                  >
                    Hire Me
                  </Button>
                </div>
              )}
            </div>
            <div className="resume--item hide-on-mobile">
              <div className="resume--item--container resume--item--header resume--item--header__space">
                <h3>Highlights</h3>
                {isEditable && (
                  <h3
                    className="resume--item--button"
                    onClick={() =>
                      this.showModal('eligibilities', 'edit', null)
                    }
                  >
                    <img src={edit} className="resume--item--icon" />
                  </h3>
                )}
              </div>
              <div className="resume--item--container">
                {profile
                  .get('eligibilities')
                  .valueSeq()
                  .map((country, index) => (
                    <span
                      key={country}
                      style={{ marginRight: '15px', marginTop: '15px' }}
                    >
                      <Tag style={{ marginBottom: '15px' }}>{country}</Tag>
                    </span>
                  ))}
              </div>
            </div>
            <div style={{display: 'none'}} className="resume--item">
              <div className="resume--item--container resume--item--header resume--item--header__space">
                <h3>Skills and Expertise</h3>

                {isEditable && (
                  <h3
                    className="resume--item--button"
                    onClick={() => this.showModal('skills', 'edit', null)}
                  >
                    <img src={edit} className="resume--item--icon" />
                  </h3>
                )}
              </div>
              <div className="resume--item--container">
                {skils.map((skill, index) => (
                  <div key={`${index}-${skill.rating}`}>
                    <div className="slider--text">
                      <span>{skill.name}</span>
                      <span style={{ float: 'right', opacity: 0.6 }}>
                        {' '}
                        {skill.rate}{' '}
                      </span>
                    </div>
                    <Slider
                      min={1}
                      max={6}
                      marks={{ 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' }}
                      defaultValue={skill.rating + 1}
                      disabled={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={24} md={17} span={17}>
            <div className="resume--item">
              <div className="resume--item--container resume--item--header resume--item--header__space">
                <h3>
                  <img src={user} className="resume--item--icon" />
                  <span> Profile Overview </span>
                </h3>
                {isEditable && (
                  <h3
                    className="resume--item--button"
                    onClick={() => this.showModal('about', 'edit', null)}
                  >
                    <img src={edit} className="resume--item--icon" />
                    {/* <span> Edit Details </span> */}
                  </h3>
                )}
              </div>
              <div className="resume--item--container">
                <p style={{ opacity: 0.6, whiteSpace: 'pre-line' }}>
                  {profile.get('about')}
                </p>
              </div>
            </div>
            <div className="resume--item hide-on-desktop">
              <div className="resume--item--container resume--item--header resume--item--header__space">
                <h3>Highlights</h3>
                {isEditable && (
                  <h3
                    className="resume--item--button"
                    onClick={() =>
                      this.showModal('eligibilities', 'edit', null)
                    }
                  >
                    <img src={edit} className="resume--item--icon" />
                  </h3>
                )}
              </div>
              <div className="resume--item--container">
                {profile
                  .get('eligibilities')
                  .valueSeq()
                  .map((country, index) => (
                    <span
                      key={country}
                      style={{ marginRight: '15px', marginTop: '15px' }}
                    >
                      <Tag style={{ marginBottom: '15px' }}>{country}</Tag>
                    </span>
                  ))}
              </div>
            </div>
            {(profile.get('experiences').size === 0 && !isEditable
              ? false
              : true) && (
              <div className="resume--item">
                <div className="resume--item--container resume--item--header resume--item--header__space">
                  <h3>
                    <img src={work} className="resume--item--icon" />
                    <span> Experience and Projects </span>
                  </h3>
                  {isEditable && (
                    <h4
                      className="resume--item--button resume--item--button__add"
                      onClick={() =>
                        this.showModal('experiences', 'create', null)
                      }
                    >
                      + Add more experience
                    </h4>
                  )}
                </div>
                <Row type="flex">
                  <Col span={1} className="resume-item-padding">
                    {' '}
                    &nbsp;{' '}
                  </Col>
                  <Col span={23} className="resume--item--container">
                    {profile
                      .get('experiences')
                      .valueSeq()
                      .sort((a, b) => {
                        if (
                          moment(a.get('from')).isAfter(moment(b.get('from')))
                        ) {
                          return -1;
                        }

                        if (
                          moment(a.get('from')).isBefore(moment(b.get('from')))
                        ) {
                          return 1;
                        }

                        return 0;
                      })
                      .map((experienceItem, index) => {
                        return (
                          <div
                            key={index}
                            className="experience-container"
                            style={{ marginRight: '15px', marginBottom: '5%' }}
                          >
                            <div className="resume--item--header__space">
                              <span className="resume-bullet" />
                              <h4 style={{ fontWeight: 600, marginBottom: 0 }}>
                                {experienceItem.get('title')}
                              </h4>
                              <p className="resume-date hide-on-mobile">
                                <img src={date} />
                                <span className="resume--item--content">
                                  {moment(experienceItem.get('from')).format(
                                    'MMM YYYY'
                                  )}{' '}
                                  -
                                  {experienceItem.get('to')
                                    ? ` ${moment(
                                        experienceItem.get('to')
                                      ).format('MMM YYYY')}`
                                    : ' Present'}
                                </span>
                              </p>
                              {isEditable && (
                                <h4
                                  className="resume--item--button"
                                  onClick={() =>
                                    this.showModal(
                                      'experiences',
                                      'edit',
                                      experienceItem.get('id')
                                    )
                                  }
                                >
                                  <img
                                    src={edit}
                                    className="resume--item--icon"
                                  />
                                  {/* <span> Edit Details </span> */}
                                </h4>
                              )}
                            </div>
                            <div className="resume--item--meta">
                              <p>
                                <img src={building} />
                                <span className="resume--item--content">
                                  {experienceItem.get('company_name')}
                                </span>
                              </p>
                              <p className="resume-date hide-on-desktop">
                                <img src={date} />
                                <span className="resume--item--content">
                                  {moment(experienceItem.get('from')).format(
                                    'MMM YYYY'
                                  )}{' '}
                                  -
                                  {experienceItem.get('to')
                                    ? ` ${moment(
                                        experienceItem.get('to')
                                      ).format('MMM YYYY')}`
                                    : ' Present'}
                                </span>
                              </p>
                              <p className="hide-on-mobile">
                                <img src={locationIcon} />
                                <span className="resume--item--content">
                                  {experienceItem.get('location')}
                                </span>
                              </p>
                            </div>
                            <p style={{ opacity: 0.6, whiteSpace: 'pre-line' }}>
                              {experienceItem.get('description')}
                            </p>

                            {experienceItem.get('projects').size > 0 && (
                              <div className="resume--item--header__space">
                                <h4
                                  style={{
                                    fontWeight: 600,
                                    color: 'black',
                                    fontSize: '18px',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                  }}
                                >
                                  Projects
                                </h4>
                                {isEditable && (
                                  <h4
                                    style={{ marginTop: '20px' }}
                                    className="resume--item--button resume--item--button__add"
                                    onClick={() =>
                                      this.showModal(
                                        'project',
                                        'create',
                                        experienceItem.get('id')
                                      )
                                    }
                                  >
                                    + Add more projects
                                  </h4>
                                )}
                              </div>
                            )}

                            {experienceItem.get('projects').size === 0 && (
                              <div className="resume--item--header-right">
                                {isEditable && (
                                  <h4
                                    style={{ marginTop: '20px', fontSize: '15px' }}
                                    className="resume--item--button resume--item--button__add"
                                    onClick={() =>
                                      this.showModal(
                                        'project',
                                        'create',
                                        experienceItem.get('id')
                                      )
                                    }
                                  >
                                    + Add more projects
                                  </h4>
                                )}
                              </div>
                            )}
                            {experienceItem.get('projects').size > 0 &&
                              experienceItem
                                .get('projects')
                                .valueSeq()
                                .map((project, i) => {
                                  return (
                                    <div key={i} className="project--container">
                                      <div className="resume--item--header__space">
                                        <span className="resume-bullet" />
                                        <h4 style={{ textDecoration: 'underline', fontWeight: 500 }}>
                                          <a
                                            href={project.get('rig_name')}
                                            target="_blank"
                                          >
                                            {project.get('client_name')}
                                          </a>
                                        </h4>
                                        {isEditable && (
                                          <h4
                                            className="resume--item--button"
                                            onClick={() => {
                                              return this.showModal(
                                                'project',
                                                'edit',
                                                project.get('experience_id'),
                                                project.get('id')
                                              );
                                            }}
                                          >
                                            <img
                                              src={edit}
                                              className="resume--item--icon"
                                            />
                                            {/* <span> Edit Details</span> */}
                                          </h4>
                                        )}
                                      </div>

                                      <h4 style={{ fontWeight: 100 }}>
                                        {project.get('well_name')}
                                      </h4>
                                      <p style={{ whiteSpace: 'pre-line' }}>
                                        {project.get('achievements')}
                                      </p>
                                    </div>
                                  );
                                })}
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </div>
            )}
            {(profile.get('educations').size === 0 && !isEditable
              ? false
              : true) && (
              <div className="resume--item">
                <div className="resume--item--container resume--item--header resume--item--header__space">
                  <h3>
                    <img src={education} className="resume--item--icon" />
                    <span> Education</span>
                  </h3>
                  {isEditable && (
                    <h4
                      className="resume--item--button resume--item--button__add"
                      onClick={() =>
                        this.showModal('educations', 'create', null)
                      }
                    >
                      + Add more educations
                    </h4>
                  )}
                </div>
                <Row type="flex">
                  <Col span={1} className="resume-item-padding">
                    {' '}
                    &nbsp;{' '}
                  </Col>
                  <Col span={23} className="resume--item--container">
                    {profile
                      .get('educations')
                      .valueSeq()
                      .sort((a, b) => {
                        if (
                          moment(a.get('start_date')).isAfter(moment(b.get('start_date')))
                        ) {
                          return -1;
                        }

                        if (
                          moment(a.get('start_date')).isBefore(moment(b.get('start_date')))
                        ) {
                          return 1;
                        }

                        return 0;
                      })
                      .map((educationItem, index) => (
                        <div
                          key={index}
                          style={{ marginRight: '15px', marginTop: '15px' }}
                        >
                          <div className="resume--item--header__space">
                            <h4 style={{ fontWeight: 600, marginBottom: 0 }}>
                              <span className="resume-bullet" />
                              {educationItem.get('degree')}
                            </h4>
                            <p className="resume-date">
                              <img src={date} />
                              <span className="resume--item--content">
                                {moment(educationItem.get('start_date')).format(
                                  'MMM YYYY'
                                )}{' '}
                                -
                                {educationItem.get('end_date')
                                  ? ` ${moment(
                                      educationItem.get('end_date')
                                    ).format('MMM YYYY')}`
                                  : ' Present'}
                              </span>
                            </p>
                            {isEditable && (
                              <h4
                                className="resume--item--button"
                                onClick={() =>
                                  this.showModal(
                                    'educations',
                                    'edit',
                                    educationItem.get('id')
                                  )
                                }
                              >
                                <img
                                  src={edit}
                                  className="resume--item--icon"
                                />
                                {/* <span> Edit Details</span> */}
                              </h4>
                            )}
                          </div>
                          <div className="resume--item--meta">
                            <p>
                              <img src={building} />
                              <span className="resume--item--content">
                                {educationItem.get('name')}
                              </span>
                            </p>
                            <p className="hide-on-mobile">
                              <img src={locationIcon} />
                              <span className="resume--item--content">
                                {educationItem.get('location')}
                              </span>
                            </p>
                          </div>
                          <p style={{ opacity: 0.6, whiteSpace: 'pre-line' }}>
                            {educationItem.get('description')}
                          </p>
                        </div>
                      ))}
                  </Col>
                </Row>
              </div>
            )}
            {((profile.get('certifications')
              ? profile.get('certifications').toArray().length === 0
              : true) && !isEditable
              ? false
              : true) && (
              <div className="resume--item">
                <div className="resume--item--container resume--item--header resume--item--header__space">
                  <h3>
                    <img src={certifications} className="resume--item--icon" />
                    <span> Certifications </span>
                  </h3>
                  {isEditable && (
                    <h4
                      className="resume--item--button resume--item--button__add"
                      onClick={() =>
                        this.showModal('certifications', 'create', null)
                      }
                    >
                      + Add more certifications
                    </h4>
                  )}
                </div>
                <Row type="flex">
                  <Col span={1} className="resume-item-padding">
                    {' '}
                    &nbsp;{' '}
                  </Col>
                  <Col span={23} className="resume--item--container">
                    {profile.get('certifications') &&
                      profile
                        .get('certifications')
                        .valueSeq()
                        .sort((a, b) => {
                          if (
                            moment(a.get('start_date')).isAfter(moment(b.get('start_date')))
                          ) {
                            return -1;
                          }
  
                          if (
                            moment(a.get('start_date')).isBefore(moment(b.get('start_date')))
                          ) {
                            return 1;
                          }
  
                          return 0;
                        })
                        .map((certificationItem, index) => (
                          <div
                            key={index}
                            style={{ marginRight: '15px', marginTop: '15px' }}
                          >
                            <div className="resume--item--header__space">
                              <h4 style={{ fontWeight: 600 }}>
                                <span className="resume-bullet" />
                                {certificationItem.get('certificate_name')}
                              </h4>
                              <p className="resume-date">
                                <img src={date} />
                                <span className="resume--item--content">
                                  {moment(
                                    certificationItem.get('start_date')
                                  ).format('MMM YYYY')}{' '}
                                  -
                                  {certificationItem.get('end_date')
                                    ? ` ${moment(
                                        certificationItem.get('end_date')
                                      ).format('MMM YYYY')}`
                                    : ' Present'}
                                </span>
                              </p>
                              {isEditable && (
                                <h4
                                  className="resume--item--button"
                                  onClick={() =>
                                    this.showModal(
                                      'certifications',
                                      'edit',
                                      certificationItem.get('id')
                                    )
                                  }
                                >
                                  <img
                                    src={edit}
                                    className="resume--item--icon"
                                  />
                                  {/* <span> Edit Details </span> */}
                                </h4>
                              )}
                            </div>
                            <div className="resume--item--meta">
                              <p>
                                <img src={building} />
                                <span className="resume--item--content">
                                  {certificationItem.get('institute_name')}
                                </span>
                              </p>
                              <p className="hide-on-mobile">
                                <img src={locationIcon} />
                                <span className="resume--item--content">
                                  {certificationItem.get('location')}
                                </span>
                              </p>
                            </div>
                            <p style={{ opacity: 0.6, whiteSpace: 'pre-line' }}>
                              {certificationItem.get('description')}
                            </p>
                          </div>
                        ))}
                  </Col>
                </Row>
              </div>
            )}

            {((profile.get('events')
              ? profile.get('events').toArray().length === 0
              : true) && !isEditable
              ? false
              : true) && (
              <div className="resume--item">
                <div className="resume--item--container resume--item--header resume--item--header__space">
                  <h3>
                    <img src={awards} className="resume--item--icon" />
                    <span> Awards, Events And Publications </span>
                  </h3>
                  {isEditable && (
                    <h4
                      className="resume--item--button resume--item--button__add"
                      onClick={() => this.showModal('events', 'create', null)}
                    >
                      + Add more events
                    </h4>
                  )}
                </div>
                <Row type="flex">
                  <Col span={1} className="resume-item-padding">
                    {' '}
                    &nbsp;{' '}
                  </Col>
                  <Col span={23} className="resume--item--container">
                    {profile.get('events') &&
                      profile
                        .get('events')
                        .valueSeq()
                        .sort((a, b) => {
                          if (
                            moment(a.get('event_date')).isAfter(moment(b.get('event_date')))
                          ) {
                            return -1;
                          }
  
                          if (
                            moment(a.get('event_date')).isBefore(moment(b.get('event_date')))
                          ) {
                            return 1;
                          }
  
                          return 0;
                        })
                        .map((eventItem, index) => (
                          <div
                            key={index}
                            style={{ marginRight: '15px', marginTop: '15px' }}
                          >
                            <div className="resume--item--header__space">
                              <h4 style={{ fontWeight: 600, marginBottom: 0 }}>
                                <span className="resume-bullet" />
                                {eventItem.get('name')}
                              </h4>
                              <p className="resume-date">
                                <img src={date} />
                                <span className="resume--item--content">
                                  {moment(eventItem.get('event_date')).format(
                                    'MMM YYYY'
                                  )}
                                </span>
                              </p>
                              {isEditable && (
                                <h4
                                  className="resume--item--button"
                                  onClick={() =>
                                    this.showModal(
                                      'events',
                                      'edit',
                                      eventItem.get('id')
                                    )
                                  }
                                >
                                  <img
                                    src={edit}
                                    className="resume--item--icon"
                                  />
                                  {/* <span> Edit Details </span> */}
                                </h4>
                              )}
                            </div>
                            <div className="resume--item--meta">
                              <p className="hide-on-mobile">
                                <img src={locationIcon} />
                                <span className="resume--item--content">
                                  {eventItem.get('institute_name')}
                                </span>
                              </p>
                            </div>
                            <p style={{ opacity: 0.6, whiteSpace: 'pre-line' }}>
                              {eventItem.get('description')}
                            </p>
                          </div>
                        ))}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>

        {isEditable ? (
          <Modal
            footer={null}
            onCancel={this.handleCancel}
            title={` ${this.state.modalMode === 'edit' ? 'Edit' : 'Add'} ${
              this.state.title
            }`}
            visible={this.state.visible}
            destroyOnClose={true}
          >
            <EditForm
              addExperiences={this.props.addExperiences}
              addProjects={this.props.addProjects}
              addEducations={this.props.addEducations}
              addCertifications={this.props.addCertifications}
              addSkills={this.props.addSkills}
              addEvents={this.props.addEvents}
              deleteCertification={this.props.deleteCertification}
              deleteEducation={this.props.deleteEducation}
              deleteEvent={this.props.deleteEvent}
              deleteExperience={this.props.deleteExperience}
              deleteProject={this.props.deleteProject}
              editCertification={this.props.editCertification}
              editEducation={this.props.editEducation}
              editEligibilities={this.props.editEligibilities}
              editEvent={this.props.editEvent}
              editExperience={this.props.editExperience}
              editProfileInfo={this.props.editProfileInfo}
              editProject={this.props.editProject}
              editType={this.state.editType}
              isDelete={this.state.isDelete}
              loading={profile.get('loading')}
              modalData={this.state.modalData}
              modalMode={this.state.modalMode}
            />
          </Modal>
        ) : (
          <Modal
            footer={null}
            onCancel={this.handleCancel}
            title={`Enter Company Details`}
            visible={this.state.visible}
            destroyOnClose={true}
            className={'hireme-modal'}
          >
            <HireMeForm closeForm={this.handleCancel} />
          </Modal>
        )}
      </Content>
    );
  }
}

export default Resume;
