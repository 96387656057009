import { combineEpics } from 'redux-observable';

// Epics
import { epics as authEpics } from './auth';
import { epics as profileEpics } from './profile';


const rootEpic = combineEpics(
  authEpics,
  profileEpics
);


export default rootEpic;
