import React, { Component, FormEvent } from 'react';
import { Button, Col, Form, Input, message, Row, } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
const FormItem = Form.Item;

interface HireMeProps extends FormComponentProps {
  closeForm: () => void;
}


class HireMeForm extends Component<HireMeProps> {

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    const { form, closeForm } = this.props;
    form.validateFields((err: Error, values: {}) => {
      if (!err) {
        const url = e.target.action;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function () {
          // console.log(xhr.status, xhr.statusText);
          // console.log(xhr.responseText);
          if (xhr.readyState === 4) {
            message.success('Email sent to support@flexiple');
            closeForm();
          }
          return;
        };
        // url encode form data for sending as post data
        delete values.id;
        const encoded = Object.keys(values).map(function (k: string) {
          return encodeURIComponent(k) + '=' + encodeURIComponent(values[k]);
        }).join('&');
        xhr.send(encoded);
      }
    });
  }

  validatePhonenumber(rule: {}, value: string, callback: (err?: {}) => {}) {
    if (value) {
      // let phoneNumber = parseInt(value, 10);
      if (isNaN(+value) || value.length < 10) {
        callback('Please enter a valid phone number!');
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <form id="gform" onSubmit={this.handleSubmit} method="POST"
        action="https://script.google.com/macros/s/AKfycbw89a7V_PFH5SfMjUCTN6OqcQ8WetpBvNZ280EfV49vZwAolrg/exec">
        <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
          <Row gutter={30}>
            <Col span={12}>
              <FormItem label="Name" className="form-item" colon={false}>
                {getFieldDecorator(`name`, {
                  rules: [{ required: true, message: 'Please enter your name', whitespace: true }],
                })(
                  <Input
                    name="Name"
                    placeholder="Eg. John Doe"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Company Name" className="form-item" colon={false}>
                {getFieldDecorator(`companyName`, {
                  rules: [{ required: true, message: 'Please enter your Company Name', whitespace: true }],
                })(
                  <Input
                    name="Company Name"
                    placeholder="Eg. Flexiple"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <FormItem label="Email" className="form-item" colon={false}>
                {getFieldDecorator(`email`, {
                  rules: [{ required: true, message: 'Please enter your email' }]
                })(
                  <Input
                    name="Email"
                    placeholder="Eg. abcd@gmail.com"
                    type="email"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Phone Number"
                className="form-item"
                colon={false}
              >
                {getFieldDecorator(`phone`, {
                  rules: [{
                    required: true, message: 'Please enter the phone number', whitespace: false
                  }, {
                    validator: this.validatePhonenumber.bind(this)
                  }],
                })(
                  <Input
                    name="Phone Number"
                    placeholder="Eg. 98765431234"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem className="form-item hidden">
            {getFieldDecorator(`id`, {})(
              <Input type="text" />
            )}
          </FormItem>
          <div className="modal-footer">
            <Row type="flex" justify="end">
              <Button type="primary" htmlType="submit" className="btn--submit">
                Submit Request
              </Button>
            </Row>
          </div>
        </div>
      </form>
    );
  }
}


export default Form.create<HireMeProps>()(HireMeForm);
