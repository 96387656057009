import React, { Component, FormEvent } from 'react';
import { Button, Col, DatePicker, Dropdown, Form, Icon, Input, Menu, Modal, Row, Spin } from 'antd';
import { history } from '@src/redux/store';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Experience, Project } from '@src/core/models';
import { EditFormProps } from '@src/core/props';
import { validateEndDate, validateStartDate } from '@src/core/validations';
import moment from 'moment';

import '@src/css/Work.less';

const FormItem = Form.Item;
const { TextArea } = Input;

let uuid = 1;
let puuid = 0;


interface FormState {
  showAddProjectModal: boolean;
  currentWorkFrom: number;
  editProjectIndex: string;
  company_name: string;
  description: string;
  from: string;
  id: number;
  location: string;
  title: string;
  to: string;
}

interface FormProps extends FormComponentProps {
  experience?: Experience;
  mode?: string;
}


class ExperienceForm extends Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);

    this.state = {
      currentWorkFrom: null,
      editProjectIndex: '',
      showAddProjectModal: false,
      company_name: '',
      description: '',
      from: '',
      id: null,
      location: '',
      title: '',
      to: ''
    };
  }

  componentWillMount() {
    if (this.props.experience) {
      this.setExperienceFormState(this.props.experience);
    }
  }

  componentWillReceiveProps(nextProps: FormProps) {
    if (this.props.experience && nextProps.experience && this.props.experience !== nextProps.experience) {
      this.setExperienceFormState(nextProps.experience);
    }
  }

  setExperienceFormState(experience: Experience) {
    this.setState({
      company_name: experience.get('company_name'),
      description: experience.get('description'),
      from: moment(experience.get('from')).toString(),
      id: experience.get('id'),
      location: experience.get('location'),
      title: experience.get('title'),
      to: experience.get('to') ? moment(experience.get('to')).toString() : null,
    });
  }

  handleAddExperience = () => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(uuid);
    uuid++;

    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  handleRemoveExperience = (k: number) => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('keys');

    // We need at least one experience
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key: number) => key !== k),
    });
  }

  handleRemoveProject = (project: object) => {
    const { form } = this.props;
    const keys = form.getFieldValue('projectKeys');

    if (keys.length === 0) {
      return;
    }

    form.setFieldsValue({
      projectKeys: keys.filter((key: object) => key !== project),
    });
  }

  handleAddProject = (e: FormEvent<{}>) => {
    e.preventDefault();
    this.props.form.validateFields(['client_name', 'rig_name', 'well_name', 'from', 'to', 'achievements'],
      (err, values) => {
        if (!err) {
          const { form } = this.props;
          // console.log('Received values of project form: ', values);
          const keys = form.getFieldValue(`projectKeys`);
          values.index = `form${this.state.currentWorkFrom}-${puuid}`;

          keys.map((key: Project, i: string) => {
            if (key.index === this.state.editProjectIndex) {
              keys.splice(i, 1);
              values.index = this.state.editProjectIndex;
              return;
            }
          });

          const nextKeys = keys.concat(values);
          puuid++;

          this.setState({
            editProjectIndex: '',
            showAddProjectModal: false
          });

          form.setFieldsValue({
            projectKeys: nextKeys,
          });

          this.props.form.resetFields(['client_name', 'rig_name', 'well_name', 'from', 'to', 'achievements']);
        }
      });
  }

  handleShowModal = (workFormIndex: number) => {
    this.setState({
      currentWorkFrom: workFormIndex,
      showAddProjectModal: true,
    });
  }

  handleCancel = () => {
    this.setState({ showAddProjectModal: false });
  }

  handleProjectMenuClick = (e: FormEvent<{}>, project: Project) => {
    if (e.key === 'delete') {
      this.handleRemoveProject(project);
    }

    if (e.key === 'edit') {
      this.setState({
        editProjectIndex: project.index,
        showAddProjectModal: true,
      });

      this.props.form.setFieldsValue({
        achievements: project.achievements,
        client_name: project.client_name,
        rig_name: project.rig_name,
        well_name: project.well_name,
        from: project.from,
        to: project.to,        
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { showAddProjectModal } = this.state;
    const state = this.state;

    getFieldDecorator('keys', { initialValue: [0] });

    const keys = getFieldValue('keys');

    const formItems = keys.map((k: number, index: number) => {
      getFieldDecorator(`projectKeys`, { initialValue: [] });

      const projectKeys = getFieldValue(`projectKeys`);

      const projectItems = projectKeys.map((project: Project) => {
        if (project.index.split('-')[0].slice(-1) === k.toString()) {
          const isLongAchievements = project.achievements.length > 120;
          return (
            <Col key={project.index} span={8}>
              <div className="project-item">
                <Dropdown
                  overlay={(
                    <Menu onClick={(e: FormEvent<{}>) => this.handleProjectMenuClick(e, project)}>
                      <Menu.Item
                        key="edit"
                        className="menu--item__positive"
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        key="delete"
                        className="menu--item__negative"
                      >
                        Delete
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Icon className="ellipsis" type="ellipsis" />
                </Dropdown>
                <h4>{project.client_name}</h4>
                <h4 style={{ fontWeight: 300 }}>{project.well_name} | {project.rig_name}</h4>
                <h4 style={{ opacity: 0.6, fontWeight: 300, marginTop: '10px' }}>
                  {isLongAchievements ? `${project.achievements.slice(0, 120)}...` : project.achievements}
                </h4>
              </div>
            </Col>
          );
        } else {
          return null;
        }
      });

      return (
        <div key={k} className="work-form" style={(index > 0) ? { paddingTop: '20px' } : {}}>
          <Row gutter={30}>
            {keys.length > 1 ? (
              <Icon
                onClick={() => this.handleRemoveExperience(k)}
                className="dynamic-delete-button"
                type="minus-circle-o"
              />
            ) : null}
            <Col span={12}>
              <FormItem label="Title" className="form-item" colon={false}>
                {getFieldDecorator(`${k}.title`, {
                  rules: [{ required: true, message: 'Please enter the title', whitespace: true },
                  { min: 3, message: 'Title should be at least 3 characters!' }],
                  initialValue: state.title
                })(
                  <Input
                    // placeholder="Eg. General field engineer"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Company Name" className="form-item" colon={false}>
                {getFieldDecorator(`${k}.company_name`, {
                  rules: [{ required: true, message: 'Please enter the company name', whitespace: true },
                  { min: 3, message: 'Company Name should be at least 3 characters!' }],
                  initialValue: state.company_name
                })(
                  <Input
                    // placeholder="Eg. Schlumberger"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <Row gutter={30}>
                <Col span={12}>
                  <FormItem
                    label="Start Date"
                    className="form-item"
                    colon={false}
                  >
                    {getFieldDecorator(`${k}.from`, {
                      rules: [{
                        type: 'object',
                        required: true,
                        message: 'Please select the start date'
                      }, {
                        validator: validateStartDate.bind(this, `${k}.to`)
                      }],
                      initialValue: state.from ? moment(state.from) : undefined,
                    })(
                      <DatePicker />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="End Date"
                    className="form-item"
                    colon={false}
                  >
                    {getFieldDecorator(`${k}.to`, {
                      rules: [
                        // { type: 'object', required: false, message: 'Please select the end date' },
                        { validator: validateEndDate.bind(this, `${k}.from`) }],
                      initialValue: state.to ? moment(state.to) : undefined
                    })(
                      <DatePicker />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <FormItem label="Location" className="form-item" colon={false}>
                {getFieldDecorator(`${k}.location`, {
                  rules: [{ required: true, message: 'Please enter the location', whitespace: true },
                  { min: 3, message: 'Location should be at least 3 characters!' }],
                  initialValue: state.location
                })(
                  <Input
                    // placeholder="Eg. St. Mary Street, Ukrraine"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <FormItem label="Description" className="form-item" colon={false}>
              {getFieldDecorator(`${k}.description`, {
                rules: [{ required: true, message: 'Please enter the description', whitespace: true },
                { min: 3, message: 'Description should be at least 3 characters!' }],
                initialValue: state.description,
              })(
                <TextArea 
                  // placeholder="Please enter a brief description"
                />
              )}
            </FormItem>
          </Row>
          <FormItem className="form-item hidden">
            {getFieldDecorator(`${k}.id`, {
              initialValue: state.id
            })(
              <Input type="text" />
            )}
          </FormItem>
          <h4>Projects</h4>
          <Row gutter={40}>
            {projectItems}
            <Col span={8}>
              <Button type="dashed" onClick={() => this.handleShowModal(k)} className="add-project">
                <Icon type="plus" />Add Project
              </Button>
            </Col>
          </Row>
        </div>
      );
    });


    return (
      <div className="projectExpContainer">
        {formItems}
        <Modal
          visible={showAddProjectModal}
          title="Add Project"
          onOk={this.handleAddProject}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="submit"
              htmlType="submit"
              className="btn--submit"
              onClick={this.handleAddProject}
            >
              Add Project
            </Button>,
          ]}
        >
          <div className="projectExpContainer">
            <Form onSubmit={this.handleAddProject} >
              <FormItem label="Project Name" className="form-item" colon={false}>
                {getFieldDecorator(`client_name`, {
                  rules: [{ required: true, message: 'Please enter the Project Name', whitespace: true },
                  { min: 3, message: 'Project Name should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. Schlumberger"
                    type="text"
                  />
                )}
              </FormItem>
              <Row gutter={30}>
                <Col span={12}>
                  <FormItem label="Skills" className="form-item" colon={false}>
                    {getFieldDecorator(`well_name`, {
                      rules: [{ required: true, message: 'Please enter your skills', whitespace: true },
                      { min: 3, message: 'Skills should be at least 3 characters!' }],
                    })(
                      <TextArea
                        // placeholder="Eg. Schlumberger"
                        rows={2}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="App Link" className="form-item" colon={false}>
                    {getFieldDecorator(`rig_name`)(
                      <Input
                        // placeholder="Eg. Schlumberger"
                        type="url"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <FormItem
                    label="Start Date"
                    className="form-item"
                    colon={false}
                  >
                    {getFieldDecorator(`from`, {
                      rules: [{ required: true, message: 'Please enter Start Date' },
                      { validator: validateStartDate.bind(this, 'to') }]
                    })(
                      <DatePicker />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="End Date"
                    className="form-item"
                    colon={false}
                  >
                    {getFieldDecorator(`to`, {
                      rules: [{ validator: validateEndDate.bind(this, 'from') }]
                    })(
                      <DatePicker />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <FormItem label="Description" className="form-item" colon={false}>
                {getFieldDecorator(`achievements`, {
                  rules: [{ required: true, message: 'Please enter the Description', whitespace: true },
                  { min: 3, message: 'Description should be at least 3 characters!' }],
                })(
                  <TextArea rows={2}
                  // placeholder="Write description here..."
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </Modal>
        {(!this.props.mode || this.props.mode !== 'edit') && <Row>
          <h4 onClick={this.handleAddExperience} className="add-experience">+ Add more experience</h4>
        </Row>}
      </div>
    );
  }
}


const experienceForm = Form.create<{}>()(ExperienceForm);

export default experienceForm;
