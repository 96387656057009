import React, { Component, FormEvent } from 'react';
import { Button, Col, DatePicker, Dropdown, Form, Icon, Input, Menu, Modal, Row, Spin } from 'antd';

import { history } from '@src/redux/store';

import { Experience, Project } from '@src/core/models';
import { WorkProps } from '@src/core/props';
import { validateEndDate, validateStartDate } from '@src/core/validations';

// import ExperienceForm from '../Form/ExperienceProject';
import { ExperienceForm } from '../Form';
import '@src/css/Work.less';

const FormItem = Form.Item;
const { TextArea } = Input;

let uuid = 1;
let puuid = 0;


interface WorkState {
  showAddProjectModal: boolean;
  currentWorkFrom: number;
  editProjectIndex: string;
}


class WorkForm extends Component<WorkProps, WorkState> {

  private experienceForm: ExperienceForm;

  constructor(props: WorkProps) {
    super(props);

    this.state = {
      currentWorkFrom: null,
      editProjectIndex: '',
      showAddProjectModal: false,
    };
  }

  componentWillReceiveProps(nextProps: WorkProps) {
    if (nextProps.profile.get('experiences').size > 0) {
      nextProps.updateStep(3);
      history.push('/profile/achievements/');
    }
  }

  handleSubmit = (e: FormEvent<{}>) => {
    const allFields: string[] = [];
    e.preventDefault();
    for (const key of this.experienceForm.props.form.getFieldValue('keys')) {
      allFields.push(`${key}.company_name`);
      allFields.push(`${key}.description`);
      allFields.push(`${key}.from`);
      allFields.push(`${key}.location`);
      allFields.push(`${key}.to`);
      allFields.push(`${key}.title`);
    }

    this.experienceForm.props.form.validateFields(allFields, (err: Error, values) => {
      if (!err) {
        const experiences: Experience[] = [];
        const projects = this.experienceForm.props.form.getFieldValue(`projectKeys`);

        // Validate start and end date of Experiences
        for (const k of Object.keys(values)) {
          if (values[k].to && values[k].from.valueOf() > values[k].to.valueOf()) {
            return;
          }

          values[k].projects = projects.filter((p: Project) => p.index.indexOf(`form${k}-`) !== -1);

          experiences.push(values[k]);
        }

        allFields.map((k) => {
          if (k.indexOf('.from') !== -1 || k.indexOf('.to') !== -1) {
            const i = k.split('.');
            values[i[0]][i[1]] = values[i[0]][i[1]] ? values[i[0]][i[1]].format('YYYY-MM-DD') : null;
          }
        });

        this.props.addExperiences(experiences);
      }
    });
  }


  render() {
    if (this.props.profile.get('loading') || this.props.profile.get('experiences').size > 0) {
      return (
        <div className="spinner-loader">
          <Spin size="large" />
        </div>
      );
    } else {
      return (
        <Form onSubmit={this.handleSubmit} style={{ backgroundColor: 'white', padding: '30px' }}>
          <Row>
            <ExperienceForm
              wrappedComponentRef={(inst) => this.experienceForm = inst}
            />
          </Row>
          <Row type="flex" justify="end">
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="btn--submit"
              >
                Save and Proceed To Next
            </Button>
            </FormItem>
          </Row>
        </Form>
        // <ExperienceForm
        //   addExperiences={this.props.addExperiences}
        // />
      );
    }
  }
}


// const Work = Form.create<{}>()(WorkForm);

export default WorkForm;
