import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { RootState } from '@src/redux/state';
import * as AuthActions from '@src/redux/modules/auth/actions';

import Auth from '@src/views/components/Auth';


const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<{}>) => {
  return { ...ownProps.match, ...state };
};


export default connect<{}>(mapStateToProps, {
  forgot: AuthActions.ForgotPassword,
  login: AuthActions.LoginUser,
  reset: AuthActions.ResetPassword,
  resend: AuthActions.ResendVerificationEmail,
  resetAuthError: AuthActions.ResetAuthError,
  signup: AuthActions.SignupUser,
  verifyEmail: AuthActions.VerifyEmail
})(Auth);
