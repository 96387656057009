// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/login.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../img/signup.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "p {\n  font-weight: 500;\n  font-size: 14px;\n  color: #0a2543;\n}\n#oilfield .logo {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n#oilfield .logo img {\n  width: 115px;\n}\n.header--white {\n  background: #ffffff;\n  box-shadow: 0 2px 4px 0 rgba(7, 26, 40, 0.05);\n  z-index: 1;\n  position: relative;\n}\n.header--transparent {\n  background: transparent;\n}\n.header-content {\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n.header-right {\n  display: flex;\n  width: 20%;\n  align-items: center;\n  justify-content: flex-end;\n}\n.header-right .btn-header {\n  margin-right: 15px;\n}\n.header-right .logout {\n  margin-right: 15px;\n}\n.layout--img-bg {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), linear-gradient(153deg, #4500e5, #3b05c7 46%, #19008a);\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n.layout--img-bg-signup {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), linear-gradient(153deg, #4500e5, #3b05c7 46%, #19008a);\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n.layout--color-bg {\n  background-color: #f2f6f9;\n}\n.text--center {\n  text-align: center;\n}\n.text--light {\n  color: #fff;\n}\n.hidden {\n  display: none !important;\n}\n.spinner-loader {\n  text-align: center;\n}\n@icon-url : http://localhost:3000/fonts/iconfont;\n", ""]);
// Exports
module.exports = exports;
