import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FormEvent } from 'react';

import { emailValidations, passwordValidations } from '@src/core/validations';
import { AuthState } from '@src/redux/modules/auth';


const FormItem = Form.Item;


interface SignupProps {
  auth: AuthState;
  resetAuthError: () => {};
  signup: (email: string, password: string) => {};
}


class SignupForm extends Component<SignupProps & FormComponentProps> {

  componentWillReceiveProps(nextProps: SignupProps) {
    if (nextProps.auth.get('error')) {
      message.error(nextProps.auth.get('error'));
      this.props.resetAuthError();
    }
  }

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        this.props.signup(values.email, values.password);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { auth } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="form--auth form--auth__signup">
        <h3 className="text--center">Join now</h3>
        <FormItem label="Email Address">
          {getFieldDecorator('email', emailValidations)(
            <Input
              placeholder="Email Address"
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
            />
          )}
        </FormItem>
        <FormItem label="Password">
          <Link to="/auth/forgot" className="btn--forgot">Forgot?</Link>
          {getFieldDecorator('password', passwordValidations)(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="btn--auth" loading={auth.get('loading')}>
            Get Started
          </Button>
          {/* <Button type="primary" className="btn--auth btn--linkedin">
            <Icon type="linkedin" /> Signup with LinkedIn
          </Button> */}
          Already have an account? <Link to="/auth/login">Sign In</Link>
        </FormItem>
      </Form>
    );
  }

}

const Signup = Form.create<SignupProps>()(SignupForm);


export default Signup;
