import { createAction } from 'redux-actions';

import { User } from '@src/core/models';


export const AUTH_ERROR = 'app/auth/error';

export const FORGOT_PASSWORD = 'app/auth/forgot';
export const FORGOT_PASSWORD_SUCCESS = 'app/auth/forgot/success';

export const GET_AUTH_TOKEN_AND_EMAIL = 'app/auth/token+email';

export const LOGIN = 'app/auth/login';
export const LOGIN_SUCCESS = 'app/auth/login/success';

export const LOGOUT = 'app/auth/logout';

export const ONBOARD_USER = 'app/users/onboard';
export const ONBOARD_USER_SUCCESS = 'app/users/onboard/success';

export const RESET_AUTH_ERROR = 'app/auth/error/reset';

export const RESET_PASSWORD = 'app/auth/reset';
export const VERIFY_EMAIL = 'app/auth/verify+email';
export const RESEND_VERIFICATION_EMAIL = 'app/auth/resend+email';
export const RESET_PASSWORD_SUCCESS = 'app/auth/reset/success';

export const SIGNUP = 'app/auth/signup';
export const SIGNUP_SUCCESS = 'app/auth/signup/success';

export const UPDATE_STEP = 'app/users/step/update';
export const UPDATE_STEP_SUCCESS = 'app/users/step/update/success';

export const EMAIL_NOT_VERIFIED = 'app/auth/email+not+verified';

export const AuthError = createAction(AUTH_ERROR, (error: Error) => (error));

export const ForgotPassword = createAction(FORGOT_PASSWORD,
  (email: string) => ({ user: { email } }));

export const ForgotPasswordSuccess = createAction(FORGOT_PASSWORD_SUCCESS,
  (message: string) => (message));

export const GetAuthTokenAndEmail = createAction(GET_AUTH_TOKEN_AND_EMAIL);

export const LoginUser = createAction(LOGIN,
  (email: string, password: string) => ({ user: { email, password } }));

export const LoginUserSuccess = createAction(LOGIN_SUCCESS,
  (data: User) => (data));

export const EmailNotVerified = createAction(EMAIL_NOT_VERIFIED,
  (data: User) => (data));

export const LogoutUser = createAction(LOGOUT);

export const OnboardUser = createAction(ONBOARD_USER);

export const OnboardUserSuccess = createAction(ONBOARD_USER_SUCCESS);

export const ResetAuthError = createAction(RESET_AUTH_ERROR);

export const ResetPassword = createAction(RESET_PASSWORD,
  (password: string, reset_password_token: string) => ({ user: { password, reset_password_token } }));

export const VerifyEmail = createAction(VERIFY_EMAIL,
  (token: string) => ({ token: token }));

export const ResendVerificationEmail = createAction(RESEND_VERIFICATION_EMAIL,
  (email: string) => ({ user: { email } }));

export const ResetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS,
  (message: string) => (message));

export const SignupUser = createAction(SIGNUP,
  (email: string, password: string) => ({ user: { email, password } }));

export const SignupUserSuccess = createAction(SIGNUP_SUCCESS,
  (data: User) => (data));

export const UpdateStep = createAction(UPDATE_STEP, (step: number) => ({ step }));

export const UpdateStepSuccess = createAction(UPDATE_STEP_SUCCESS, (data: User) => (data));
