import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FormEvent } from 'react';

import { emailValidations } from '@src/core/validations';
import { AuthState } from '@src/redux/modules/auth';

const FormItem = Form.Item;


interface ForgotProps {
  forgot: (email: string) => {};
  auth: AuthState;
}

interface ForgotState {
  message: string;
}


class ForgotForm extends Component<ForgotProps & FormComponentProps, ForgotState> {

  constructor(props: ForgotProps & FormComponentProps) {
    super(props);
    this.state = {
      message: `Enter registered email address below and we'll send the reset password link`
    };
  }

  componentWillReceiveProps(nextProps: ForgotProps) {

    if (!nextProps.auth.get('error') &&
      !nextProps.auth.get('loading') &&
      this.props.auth.get('loading') !== nextProps.auth.get('loading')) {
      this.setState({
        message: `Password Reset email sent. Please check you email(Spam Folder)`
      });
    }

    if (nextProps.auth.get('error')) {
      message.error(nextProps.auth.get('error'));
      // this.props.resetAuthError();
    }
  }

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.forgot(values.email);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { auth } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} className="form--auth form--auth__forgot">
        <h3 className="text--center">Forgot Password?</h3>
        <p className="text--center">{this.state.message}</p>
        <FormItem label="Email Address">
          {getFieldDecorator('email', emailValidations)(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              // placeholder="Email Address"
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="btn--auth" loading={auth.get('loading')}>
            Forgot Password
          </Button>
          <Link to="/auth/login">Sign In</Link> or <Link to="/">Sign Up</Link>
        </FormItem>
      </Form>
    );
  }
}

const Forgot = Form.create<ForgotProps>()(ForgotForm);


export default Forgot;
