import { ajax } from 'rxjs/observable/dom/ajax';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

import { AjaxMethod } from './enums';
import { getAuthToken, getUserEmail } from './services';


export const API_URL: string = 'https://flesume.herokuapp.com/api/v1/';


const request = (path: string, method: AjaxMethod, body: {}) =>
  ajax({
    body,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
      'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
      'Content-Type': 'application/json',
      'X-User-Email': getUserEmail(),
      'X-User-Token': getAuthToken(),
    },
    method,
    responseType: 'json',
    timeout: 120000, // 2 min
    url: API_URL + path,
  })
    .map((e) => {
      console.log('[AJAX] Status --- ' + e.status);
      console.log(e.response);
      if (e.response && e.response.status && e.response.error && Object.keys(e.response.error).length > 0) {
        throw new Error('Error in processing the request');
      } else {
        return e.response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return Observable.throw(err.response);
      } else {
        return Observable.throw({ error: err.message });
      }
    });


export default request;
