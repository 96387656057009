import React, { Component, FormEvent } from 'react';
import { Col, Form, Input, Row, Slider, Tag } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Skill } from '@src/core/models';


const FormItem = Form.Item;


interface SkillFormProps extends FormComponentProps {
  skills: Skill[];
}


class SkillForm extends Component<SkillFormProps, {}> {

  componentDidMount() {
    const { skills } = this.props;

    if (skills) {
      this.props.form.setFieldsValue({ skills });
    }
  }

  handleClose = (skill: Skill) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    let skills = getFieldValue('skills');
    skills = skills.filter((s: Skill) => s.name !== skill.name);
    setFieldsValue({ skills });
  }

  validateSkillInput = (rule, value, callback) => {
    const { getFieldValue, resetFields, setFieldsValue } = this.props.form;

    const skill = { name: value };
    const skills = getFieldValue('skills');

    if (skill.name && skills.filter((s: Skill) => s.name.toLowerCase() === skill.name.toLowerCase()).length) {
      callback('Skill has been already added!');
    }

    callback();
  }

  handleSkillInputConfirm = (e: FormEvent<{}>) => {
    e.preventDefault();

    const { getFieldValue, resetFields, setFieldsValue } = this.props.form;

    const skill = { name: e.target.value };
    const skills = getFieldValue('skills');

    if (skill.name && !skills.filter((s: Skill) => s.name.toLowerCase() === skill.name.toLowerCase()).length) {
      skills.push(skill);
      setFieldsValue({ skills });
      resetFields(['skill']);
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const skills = getFieldValue('skills') ? getFieldValue('skills') : [];

    const skillFormItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return <div>
      <FormItem label="Relevant Skills (Add skills by pressing Enter)" className="form-item" colon={false}>
        {getFieldDecorator('skill', {
          rules: [
            { message: 'Please enter your skills!', required: skills.length === 0, whitespace: true },
            { validator: this.validateSkillInput }
          ],
        })(
          <Input
            // placeholder="Eg: UI/UX"
            type="text"
            onBlur={this.handleSkillInputConfirm}
            onPressEnter={this.handleSkillInputConfirm}
          />
        )}
      </FormItem>

      {skills.map((skill: Skill) => (
        <Row type="flex" justify="start" key={skill.name}>
          <Col span={4}>
            <Tag closable={true} onClose={() => this.handleClose(skill)}>
              {skill.name}
            </Tag>
          </Col>
          <Col span={12} >
            <FormItem
              label="Rate this skill"
              className="form-item"
              colon={false}
              {...skillFormItemLayout}
            >
              {getFieldDecorator(`${skill.name.replace(/\s+/g, '-').toLowerCase()}-rating`,
                {
                  initialValue: skill.rating ? skill.rating : 0,
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        callback(value > 0 ? undefined : 'Please rate your skill!');
                      }
                    }
                  ],
                })(
                  <Slider
                    min={0}
                    max={5}
                    marks={{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }}
                    style={{ marginLeft: '20px' }}
                  />
                )}
            </FormItem>
          </Col>
          {/* <Col span={4} >
             <p className="skill-level">Intermitent</p>
             </Col> */}
        </Row>
      )
      )}

      <FormItem className="form-item hidden">
        {getFieldDecorator('skills', {})(
          <Input type="text" />
        )}
      </FormItem>
    </div>;
  }
}

export default Form.create<SkillFormProps>()(SkillForm);
