// Reducers
import { authReducer } from './auth';
import { profileReducer } from './profile';


const rootReducers = {
  auth: authReducer,
  profile: profileReducer,
};


export default rootReducers;
