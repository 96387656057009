import React, { Component } from 'react';
import { Col, DatePicker, Form, Icon, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { validateEndDate, validateStartDate } from '@src/core/validations';
import moment from 'moment';

import { Education } from '@src/core/models';
import TextArea from 'antd/lib/input/TextArea';


const FormItem = Form.Item;

let uuid = 1;


interface EducationFormProps extends FormComponentProps {
  mode: string;
  education: Education;
}


class EducationForm extends Component<EducationFormProps> {

  componentDidMount() {
    const { education } = this.props;

    if (education) {
      this.props.form.setFieldsValue({
        educations: {
          0: {
            degree: education.get('degree'),
            description: education.get('description'),
            end_date: moment(education.get('end_date')),
            id: education.get('id'),
            location: education.get('location'),
            name: education.get('name'),
            start_date: moment(education.get('start_date')),
          }
        }
      });
    }
  }

  handleAddEducation = () => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('educationKeys');
    const nextKeys = keys.concat(uuid);
    uuid++;

    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      educationKeys: nextKeys,
    });
  }

  handleRemoveEducation = (k: number) => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('educationKeys');

    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      educationKeys: keys.filter((key: number) => key !== k),
    });
  }

  render() {
    const { mode } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('educationKeys', { initialValue: [0] });
    const educationKeys = getFieldValue('educationKeys');

    const educationItems = educationKeys.map((k: number, index: number) => {
      return (
        <div
          key={k}
          className={educationKeys.length - 1 === index ? '' : 'achievement--form'}
          style={(index > 0) ? { paddingTop: '20px' } : {}}
        >
          <Row gutter={30}>
            {educationKeys.length > 1 ? (
              <Icon
                onClick={() => this.handleRemoveEducation(k)}
                className="dynamic-delete-button"
                type="minus-circle-o"
              />
            ) : null}
            <Col span={12}>
              <FormItem label="Degree" className="form-item" colon={false}>
                {getFieldDecorator(`educations.${k}.degree`, {
                  rules: [{ required: true, message: 'Please enter the degree', whitespace: true }],
                })(
                  <Input
                    // placeholder="Eg. General field engineer"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <Row gutter={30}>
                <Col span={12}>
                  <FormItem
                    label="Start Date"
                    className="form-item"
                    colon={false}
                  >
                    {getFieldDecorator(`educations.${k}.start_date`, {
                      rules: [{ type: 'object', required: true, message: 'Please select the start date' },
                      { validator: validateStartDate.bind(this, `educations.${k}.end_date`) }],
                    })(
                      <DatePicker />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="End Date"
                    className="form-item"
                    colon={false}
                  >
                    {getFieldDecorator(`educations.${k}.end_date`, {
                      rules: [{ type: 'object', required: true, message: 'Please select the end date' },
                      { validator: validateEndDate.bind(this, `educations.${k}.start_date`) }],
                    })(
                      <DatePicker />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <FormItem label="School/Institute Name" className="form-item" colon={false}>
                {getFieldDecorator(`educations.${k}.name`, {
                  rules: [{ required: true, message: 'Please enter the school/institute', whitespace: true },
                  { min: 3, message: 'Institute Name should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. Schlumberger"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Location" className="form-item" colon={false}>
                {getFieldDecorator(`educations.${k}.location`, {
                  rules: [{ required: true, message: 'Please enter the location', whitespace: true },
                  { min: 3, message: 'Location should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. St. Mary Street, Ukraine"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <FormItem label="Description" className="form-item" colon={false}>
              {getFieldDecorator(`educations.${k}.description`, {
              })(
                <TextArea
                  // placeholder="Enter brief description of your education here"
                  rows={4}
                  // onPressEnter
                />
              )}
            </FormItem>
          </Row>

          <FormItem className="form-item hidden">
            {getFieldDecorator(`educations.${k}.id`, {})(
              <Input type="text" />
            )}
          </FormItem>
        </div>
      );
    });

    return <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
      {mode === 'add' && (
        <h3 className="section--header"> Education</h3>
      )}

      {educationItems}

      {mode === 'add' && (
        <Row>
          <h4 onClick={this.handleAddEducation} className="add-experience">+ Add more</h4>
        </Row>
      )}
    </div>;
  }
}

export default Form.create<EducationFormProps>()(EducationForm);
