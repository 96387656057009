import React, { Component } from 'react';
import { Layout } from 'antd';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Redux
import * as AuthActions from '@src/redux/modules/auth/actions';
import * as ProfileActions from '@src/redux/modules/profile/actions';
import { RootState } from '@src/redux/state';
import { AuthState } from '@src/redux/modules/auth';
import { ProfileState } from '@src/redux/modules/profile';

// Styles
import '@src/css/app.less';

// Routes
import { routes } from '../routes';

// Components
import Navbar from '@src/views/components/Navbar';

const { Content } = Layout;

const mapStateToProps = (state: RootState) => {
  return { ...state };
};

interface OilfieldProps {
  auth: AuthState;
  profile: ProfileState;
  router: {};
  getAuthTokenAndEmail: () => {};
  logout: () => {};
  resetProfile: () => {};
}

class Oilfield extends Component<OilfieldProps> {
  componentWillMount() {
    this.props.getAuthTokenAndEmail();
  }

  render() {
    const { auth, profile } = this.props;
    // this.props.router
    const location = this.props.router.location;
    const splits = location.pathname.split('/');
    const isResume = location.pathname.search('/resume/');
    const isSignup =
      location.pathname === '/' ||
      location.pathname === '/auth' ||
      location.pathname === '/auth/'
        ? true
        : false;
    let isPublicResume = false;
    if (
      (splits.length === 3 || splits.length === 4) &&
      isResume !== -1 &&
      splits[2]
    ) {
      isPublicResume = true;
    }

    return (
      <Layout
        className={
          auth.get('token').length !== 0 || isPublicResume
            ? 'layout--color-bg'
            : `${isSignup ? 'layout--img-bg-signup' : 'layout--img-bg'}`
        }
        style={{ minHeight: '100vh' }}
      >
        <Navbar
          isPublicResume={isPublicResume}
          loggedIn={auth.get('token').length !== 0}
          isOnboard={auth.get('onboarded')}
          username={profile.get('username')}
          logout={this.props.logout}
          resetProfile={this.props.resetProfile}
        />
        <Content>
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Content>
      </Layout>
    );
  }
}

export default connect<{}>(
  mapStateToProps,
  {
    getAuthTokenAndEmail: AuthActions.GetAuthTokenAndEmail,
    logout: AuthActions.LogoutUser,
    resetProfile: ProfileActions.ResetProfile
  }
)(Oilfield);
