import moment, { Moment } from 'moment';

export const emailValidations = {
  rules: [
    {
      message: 'Please input your email address',
      required: true
    },
    {
      message: 'Please enter a valid email address',
      type: 'email'
    }
  ]
};

export const passwordValidations = {
  rules: [
    {
      message: 'Password must be of at-least 6 characters',
      min: 6
    },
    {
      message: 'Please input your password',
      required: true
    }
  ]
};

export function validateStartDate(fieldName: string, rule: {}, date: Moment, callback: (err?: {}) => {}) {
  const form = this.props.form;
  if (date) {
    if (date.isAfter(moment())) {
      callback(`You can't select a future date`);
    } else if (form.getFieldValue(fieldName) && date.isAfter(form.getFieldValue(fieldName))) {
      callback(`Start date can't be after End Date`);
    } else {
      callback();
    }
  } else {
    callback();
  }
}

export function validateEndDate(fieldName: string, rule: {}, date: Moment, callback: (err?: {}) => {}) {
  const form = this.props.form;
  if (date) {
    if (date.isAfter(moment())) {
      callback(`You can't select a future date`);
    } else if (form.getFieldValue(fieldName) && date.isBefore(form.getFieldValue(fieldName))) {
      callback(`End date can't be before Start Date`);
    } else {
      callback();
    }
  } else {
    callback();
  }
}
