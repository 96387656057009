import request from '@src/core/ajax';
import { AjaxMethod } from '@src/core/enums';

import { Certification } from '@src/core/models';

export const getResume = (body: {}) => {
  return request('resume', AjaxMethod.POST, body);
};

export const verifyUsername = (body: {}) => {
  return request('profiles/verify', AjaxMethod.POST, body);
};

export const verifyPhone = (body: {}) => {
  return request('profiles/verify_phone', AjaxMethod.POST, body);
};

export const addCertifications = (body: {}) => {
  return request('certifications', AjaxMethod.POST, body);
};


export const addEducations = (body: {}) => {
  return request('educations', AjaxMethod.POST, body);
};


export const addEligibilities = (body: {}) => {
  return request('eligibilities', AjaxMethod.POST, body);
};


export const addEvents = (body: {}) => {
  return request('events', AjaxMethod.POST, body);
};


export const addExperiences = (body: {}) => {
  return request('experiences', AjaxMethod.POST, body);
};

export const addProjects = (body: {}) => {
  return request('projects', AjaxMethod.POST, body);
};

export const addProfileInfo = (body: {}) => {
  return request('profiles', AjaxMethod.POST, body);
};


export const addSkills = (body: {}) => {
  return request('skills/crud', AjaxMethod.POST, body);
};


export const deleteCertification = (id: number) => {
  return request(`certifications/${id}`, AjaxMethod.DELETE, {});
};


export const deleteEducation = (id: number) => {
  return request(`educations/${id}`, AjaxMethod.DELETE, {});
};


export const deleteEvent = (id: number) => {
  return request(`events/${id}`, AjaxMethod.DELETE, {});
};


export const deleteExperience = (id: number) => {
  return request(`experiences/${id}`, AjaxMethod.DELETE, {});
};


export const deleteProject = (id: number) => {
  return request(`projects/${id}`, AjaxMethod.DELETE, {});
};


export const editCertification = (id: number, body: {}) => {
  return request(`certifications/${id}`, AjaxMethod.PUT, body);
};


export const editEducation = (id: number, body: {}) => {
  return request(`educations/${id}`, AjaxMethod.PUT, body);
};


export const editEvent = (id: number, body: {}) => {
  return request(`events/${id}`, AjaxMethod.PUT, body);
};


export const editExperience = (id: number, body: {}) => {
  return request(`experiences/${id}`, AjaxMethod.PUT, body);
};


export const editProfileInfo = (body: {}) => {
  return request('profiles/append', AjaxMethod.PUT, body);
};


export const editProject = (id: number, body: {}) => {
  return request(`projects/${id}`, AjaxMethod.PUT, body);
};


export const getCertifications = () => {
  return request('certifications', AjaxMethod.GET, {});
};


export const getEducations = () => {
  return request('educations', AjaxMethod.GET, {});
};


export const getEligibilities = () => {
  return request('eligibilities', AjaxMethod.GET, {});
};


export const getEvents = () => {
  return request('events', AjaxMethod.GET, {});
};


export const getExperiences = () => {
  return request('experiences', AjaxMethod.GET, {});
};


export const getProfileInfo = () => {
  return request('profiles/info', AjaxMethod.GET, {});
};


export const getSkills = () => {
  return request('skills', AjaxMethod.GET, {});
};

