import React, { Component } from 'react';
import { Col, DatePicker, Form, Icon, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { validateEndDate, validateStartDate } from '@src/core/validations';
import moment from 'moment';

import { Certification } from '@src/core/models';
import TextArea from 'antd/lib/input/TextArea';


const FormItem = Form.Item;

let uuid = 1;


interface CertificationFormProps extends FormComponentProps {
  certification: Certification;
  mode: string;
}


class CertficationForm extends Component<CertificationFormProps> {

  componentDidMount() {
    const { certification } = this.props;

    if (certification) {
      this.props.form.setFieldsValue({
        certifications: {
          0: {
            certificate_name: certification.get('certificate_name'),
            end_date: moment(certification.get('end_date')),
            id: certification.get('id'),
            institute_name: certification.get('institute_name'),
            location: certification.get('location'),
            start_date: moment(certification.get('start_date')),
            description: certification.get('description'),
          }
        }
      });
    }
  }

  handleAddCertification = () => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('certificationKeys');
    const nextKeys = keys.concat(uuid);
    uuid++;

    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      certificationKeys: nextKeys,
    });
  }

  handleRemoveCertification = (k: number) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('certificationKeys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      certificationKeys: keys.filter((key: number) => key !== k),
    });
  }

  isRequired() {
    const form = this.props.form;
    let fields = form.getFieldsValue();
    let required = false;
    if (fields.certifications) {
      fields.certifications.forEach((certificate, index: number) => {
        if (certificate.certificate_name || certificate.start_date
          || certificate.end_date || certificate.institute_name ||
          certificate.location || certificate.description) {
          required = true;
        }
      });
    }
    return required;
  }

  render() {
    const { mode } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const dynamicRequire = this.isRequired.bind(this)();
    getFieldDecorator('certificationKeys', { initialValue: [0] });

    const certificationKeys = getFieldValue('certificationKeys');
    const certificationItems = certificationKeys.map((k: number, index: number) => {
      return (
        <div
          key={k}
          className={certificationKeys.length - 1 === index ? '' : 'achievement--form'}
          style={(index > 0) ? { paddingTop: '20px' } : {}}
        >
          <Row gutter={30}>
            {certificationKeys.length > 1 ? (
              <Icon
                onClick={() => this.handleRemoveCertification(k)}
                className="dynamic-delete-button"
                type="minus-circle-o"
              />
            ) : null}
            <Col span={8}>
              <FormItem label="Certification Name" className="form-item" colon={false}>
                {getFieldDecorator(`certifications.${k}.certificate_name`, {
                  rules: [{
                    required: dynamicRequire,
                    message: 'Please enter the certification name',
                    whitespace: true
                  },
                  { min: 3, message: 'Certification Name should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. Schlumberger"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Start Date"
                className="form-item"
                colon={false}
              >
                {getFieldDecorator(`certifications.${k}.start_date`, {
                  rules: [{
                    type: 'object',
                    required: dynamicRequire,
                    message: 'Please select the start date'
                  },
                  { validator: validateStartDate.bind(this, `certifications.${k}.end_date`) }],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="End Date"
                className="form-item"
                colon={false}
              >
                {getFieldDecorator(`certifications.${k}.end_date`, {
                  rules: [{
                    type: 'object',
                    required: dynamicRequire,
                    message: 'Please select the end date'
                  },
                  { validator: validateEndDate.bind(this, `certifications.${k}.start_date`) }],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <FormItem label="Institute Name" className="form-item" colon={false}>
                {getFieldDecorator(`certifications.${k}.institute_name`, {
                  rules: [
                    {
                      required: dynamicRequire,
                      message: 'Please enter the institute name',
                      whitespace: true
                    },
                    { min: 3, message: 'Institute Name should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. General field engineer"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Location" className="form-item" colon={false}>
                {getFieldDecorator(`certifications.${k}.location`, {
                  rules: [
                    {
                      required: dynamicRequire,
                      message: 'Please enter the location',
                      whitespace: true
                    },
                    { min: 3, message: 'Location should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. St. Mary Street, Ukraine"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <FormItem label="Description" className="form-item" colon={false}>
              {getFieldDecorator(`certifications.${k}.description`, {
                rules: [{ whitespace: true },
                { min: 3, message: 'Description should be at least 3 characters!' }],
              })(
                <TextArea
                  rows={4}
                  // placeholder="Enter brief description of your education here"
                />
              )}
            </FormItem>
          </Row>

          <FormItem className="form-item hidden">
            {getFieldDecorator(`certifications.${k}.id`, {})(
              <Input type="text" />
            )}
          </FormItem>
        </div>
      );
    });

    return <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
      {mode === 'add' && (
        <h3 className="section--header"> Certifications </h3>
      )}
      {certificationItems}

      {mode === 'add' && (
        <Row>
          <h4 onClick={this.handleAddCertification} className="add-experience">+ Add more</h4>
        </Row>
      )}
    </div>;
  }
}

export default Form.create<CertificationFormProps>()(CertficationForm);
