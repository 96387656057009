import React, { Component } from 'react';
import { Button, Layout } from 'antd';
import { Link } from 'react-router-dom';

import { resetAuthTokenAndEmail } from '@src/core/services';

// Assets
import logo from '@src/img/oilfield.im-logo.png';
import logoWhite from '@src/img/oilfield.im-logo-white.png';


const { Header } = Layout;


interface NavbarProps {
  loggedIn: boolean;
  username: string;
  logout: () => {};
  resetProfile: () => {};
  isPublicResume: boolean;
  isOnboard: boolean;
}


class Navbar extends Component<NavbarProps> {

  logout = () => {
    resetAuthTokenAndEmail();
    this.props.resetProfile();
    this.props.logout();
  }

  render() {
    return (
      <Header className={this.props.loggedIn || this.props.isPublicResume ? 'header--white' : 'header--transparent'}>
        <div className="header-content">
          <div className="logo">
            <img src={this.props.loggedIn || this.props.isPublicResume ? logo : logoWhite} width="80" />
          </div>
          <div className="header-right">
            {
              this.props.loggedIn && !this.props.isPublicResume && this.props.isOnboard &&
              <Button type="primary" className="btn-header">
                <Link to={`/resume/${this.props.username}/pvt`} target="_blank" >Share</Link>
              </Button>
            }
            {this.props.loggedIn && !this.props.isPublicResume && this.props.isOnboard &&
              <Button type="primary" className="btn-header" onClick={() => window.print()} >
                Download Resume
            </Button>
            }
            {/* <input type="button" value="Print this page" onClick={() => window.print()} /> */}
            {this.props.loggedIn && !this.props.isPublicResume && (
              <Link className="logout" to="/auth/login" onClick={this.logout}>Logout</Link>
            )}
          </div>
        </div>
      </Header>
    );
  }

}


export default Navbar;
