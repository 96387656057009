import React, { Component } from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';

import { Experience } from '@src/core/models';

const FormItem = Form.Item;

interface ExperienceFormProps extends FormComponentProps {
  experience: Experience;
  mode: string;
}


class ExperienceForm extends Component<ExperienceFormProps> {

  componentDidMount() {
    if (this.props.experience) {
      this.setExperienceFormFields(this.props.experience);
    }
  }

  componentWillReceiveProps(nextProps: ExperienceFormProps) {
    if (this.props.mode !== nextProps.mode) {
      if (nextProps.mode === 'create') {
        this.props.form.resetFields();
      } else if (nextProps.mode === 'edit') {
        if (nextProps.experience) {
          this.setExperienceFormFields(nextProps.experience);
        }
      }
    }
  }

  setExperienceFormFields = (experience: Experience) => {
    this.props.form.setFieldsValue({
      company_name: experience.get('company_name'),
      description: experience.get('description'),
      from: moment(experience.get('from')),
      id: experience.get('id'),
      location: experience.get('location'),
      title: experience.get('title'),
      to: experience.get('to') ? moment(experience.get('to')) : null,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return <div className="work-form" style={{backgroundColor: 'white', padding: '30px', marginBottom: '15px'}}>
      <Row gutter={30}>
        <Col span={12}>
          <FormItem label="Title" className="form-item" colon={false}>
            {getFieldDecorator(`title`, {
              rules: [{ required: true, message: 'Please enter the title', whitespace: true }],
            })(
              <Input
                // placeholder="Eg. General field engineer"
                type="text"
              />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Company Name" className="form-item" colon={false}>
            {getFieldDecorator(`company_name`, {
              rules: [{ required: true, message: 'Please enter the company name', whitespace: true }],
            })(
              <Input
                // placeholder="Eg. Schlumberger"
                type="text"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Row gutter={30}>
            <Col span={12}>
              <FormItem
                label="Start Date"
                className="form-item"
                colon={false}
              >
                {getFieldDecorator(`from`, {
                  rules: [{ type: 'object', required: true, message: 'Please select the start date' }],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="End Date"
                className="form-item"
                colon={false}
              >
                {getFieldDecorator(`to`, {
                  // rules: [{ type: 'object', required: false, message: 'Please select the end date' }],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <FormItem label="Location" className="form-item" colon={false}>
            {getFieldDecorator(`location`, {
              rules: [{ required: true, message: 'Please enter the location', whitespace: true }],
            })(
              <Input
                // placeholder="Eg. St. Mary Street, Ukrraine"
                type="text"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <FormItem label="Description" className="form-item" colon={false}>
          {getFieldDecorator(`description`, {
            rules: [{ required: true, message: 'Please enter the description', whitespace: true }],
          })(
            <Input
              // placeholder="Enter brief description of your work here"
              type="text"
            />
          )}
        </FormItem>
      </Row>

      <FormItem className="form-item hidden">
        {getFieldDecorator(`id`, {})(
          <Input type="text"/>
        )}
      </FormItem>
    </div>;
  }

}


export default Form.create<ExperienceFormProps>()(ExperienceForm);
