import request from '@src/core/ajax';
import { AjaxMethod } from '@src/core/enums';


const apiTokenKey = `apiToken`;
const emailIdKey = `emailId`;
const onboarded = `onboarded`;

export const getAuthToken = () => {
  if (!localStorage) { return ''; }

  try {
    const apiToken = localStorage.getItem(apiTokenKey);
    return (apiToken && apiToken.length !== 0) ? apiToken : '';
  } catch (error) {
    console.log(error);
    throw new Error('Error while fetching api token');
  }
};

export const getIsOnboarded = () => {
  if (!localStorage) { return ''; }

  try {
    const isOnboard = localStorage.getItem(onboarded);
    return (isOnboard && isOnboard === 'true') ? true : false;
  } catch (error) {
    console.log(error);
    throw new Error('Error while fetching is onboarded');
  }
};


export const getUserEmail = () => {
  if (!localStorage) { return ''; }

  try {
    const emailId = localStorage.getItem(emailIdKey);
    return (emailId && emailId.length !== 0) ? emailId : '';
  } catch (error) {
    console.log(error);
    throw new Error('Error while fetching user email');
  }
};


export const setAuthTokenAndEmail = (token: string, email: string, onboard: boolean) => {
  localStorage.setItem(apiTokenKey, token);
  localStorage.setItem(emailIdKey, email);
  localStorage.setItem(onboarded, onboard.toString());
};


export const setOnboarded = (onboard: boolean) => {
  localStorage.setItem(onboarded, onboard.toString());
};

export const resetAuthTokenAndEmail = () => {
  localStorage.setItem(apiTokenKey, '');
  localStorage.setItem(emailIdKey, '');
  localStorage.setItem(onboarded, '');
};


export const login = (body: {}) => {
  return request('users/sign_in', AjaxMethod.POST, body);
};


export const forgot = (body: {}) => {
  return request('users/password', AjaxMethod.POST, body);
};


export const onboardUser = (body: {}) => {
  return request('users/onboard', AjaxMethod.PATCH, body);
};


export const reset = (body: {}) => {
  return request('users/password', AjaxMethod.PUT, body);
};


export const resendEmail = (body: {}) => {
  return request('users/confirmation', AjaxMethod.POST, body);
};

export const verifyEmail = (body: { token: string }) => {
  return request(`users/confirmation?confirmation_token=${body.token}`, AjaxMethod.GET, {});
};

export const signup = (body: {}) => {
  return request('users', AjaxMethod.POST, body);
};


export const updateStep = (body: {}) => {
  return request('users/step', AjaxMethod.PATCH, body);
};
