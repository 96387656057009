import { Record } from 'immutable';

import { User } from '@src/core/models';


export interface State extends User {
  error: Error;
  loading: boolean;
  message: string;  
}


export const iAS: State = {
  email: '',
  error: null,
  loading: false,
  onboarded: false,
  step: 1,
  token: '',
  verified: undefined,
  message: ''  
};


export class AuthState extends Record(iAS) {

  constructor(params: State) {
    super(params);
  }

  get<T extends keyof State>(key: T): State[T] {
    return super.get(key);
  }

  set<T extends keyof State, V extends keyof State>(key: T, value: State[V]) {
    return super.set(key, value);
  }

}
