import React, { Component } from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment from 'moment';

import { Project } from '@src/core/models';


const FormItem = Form.Item;
const { TextArea } = Input;


interface ProjectFormProps extends FormComponentProps {
  mode: string;
  project: Project;
}


class ProjectForm extends Component<ProjectFormProps> {

  componentDidMount() {
    const { project, mode } = this.props;

    if (project && mode === 'edit') {
      this.props.form.setFieldsValue({
        achievements: project.get('achievements'),
        client_name: project.get('client_name'),
        experience_id: project.get('experience_id'),
        from: project.get('from') ? moment(project.get('from')) : undefined,
        id: project.get('id'),
        rig_name: project.get('rig_name'),
        to: project.get('to') ? moment(project.get('to')) : undefined,
        well_name: project.get('well_name'),
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
      <FormItem label="Project Name" className="form-item" colon={false}>
        {getFieldDecorator(`client_name`, {
          rules: [{ required: true, message: 'Please enter the project name', whitespace: true }],
        })(
          <Input
            // placeholder="Eg. Schlumberger"
            type="text"
          />
        )}
      </FormItem>
      <Row gutter={30}>
        <Col span={12}>
          <FormItem label="Skills" className="form-item" colon={false}>
            {getFieldDecorator(`well_name`, {
              rules: [{ required: true, message: 'Please enter your skills', whitespace: true }],
            })(
              <Input
                // placeholder="Eg. Schlumberger"
                type="text"
              />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="App Link" className="form-item" colon={false}>
            {getFieldDecorator(`rig_name`)(
              <Input
                // placeholder="Eg. Schlumberger"
                type="url"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <FormItem
            label="Start Date"
            className="form-item"
            colon={false}
          >
            {getFieldDecorator(`from`, {
              rules: [{ required: true, message: 'Please enter the start date' }]
            })(
              <DatePicker />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="End Date"
            className="form-item"
            colon={false}
          >
            {getFieldDecorator(`to`, {})(
              <DatePicker />
            )}
          </FormItem>
        </Col>
      </Row>
      <FormItem label="Description" className="form-item" colon={false}>
        {getFieldDecorator(`achievements`, {
          rules: [{ required: true, message: 'Please enter the Descriptions', whitespace: true }],
        })(
          <TextArea rows={2}
          // placeholder="Write description here..."
          />
        )}
      </FormItem>

      <FormItem className="form-item hidden">
        {getFieldDecorator(`id`, {})(
          <Input type="text" />
        )}
      </FormItem>
      <FormItem className="form-item hidden">
        {getFieldDecorator(`experience_id`, {})(
          <Input type="text" />
        )}
      </FormItem>
    </div>;
  }

}


export default Form.create<ProjectFormProps>()(ProjectForm);
