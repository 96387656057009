import { connect } from 'react-redux';

import { RootState } from '@src/redux/state';
import * as AuthActions from '@src/redux/modules/auth/actions';
import * as ProfileActions from '@src/redux/modules/profile/actions';

import Profile from '@src/views/components/Profile/Main';
import { ProfileProps } from '@src/core/props';


const mapStateToProps = (state: RootState, ownProps: ProfileProps) => {
  return { ...state };
};


export default connect<{}>(mapStateToProps, {
  addAchievements: ProfileActions.AddAchievements,
  addExperiences: ProfileActions.AddExperiences,
  addProfileInfo: ProfileActions.AddProfileInfo,
  addSkills: ProfileActions.AddSkills,
  getProfile: ProfileActions.GetProfile,
  onboardUser: AuthActions.OnboardUser,
  updateStep: AuthActions.UpdateStep,
  resetProfileError: ProfileActions.ResetProfileError,  
})(Profile);
