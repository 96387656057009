import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Col, Layout, message, Row } from 'antd';
import { RouteComponentProps } from 'react-router';

import { AuthState } from '@src/redux/modules/auth';
import { history } from '@src/redux/store';

import Forgot from '@src/views/components/Forgot';
import Landing from '@src/views/components/Landing';
import Login from '@src/views/components/Login';
import Reset from '@src/views/components/Reset';
import Signup from '@src/views/components/Signup';
import Resend from '@src/views/components/Resend';
import Verify from '@src/views/components/Verify';

import '@src/css/AuthForm.less';

const { Content } = Layout;

interface AuthProps extends RouteComponentProps<{}> {
  auth: AuthState;
  forgot: (email: string) => {};
  login: (email: string, password: string) => {};
  reset: (password: string, token: string) => {};
  resend: (email: string) => {};
  resetAuthError: () => {};
  signup: (email: string, password: string) => {};
  verifyEmail: (token: string) => {};
}

class Auth extends Component<AuthProps, {}> {
  componentWillMount() {
    if (
      this.props.auth.get('token').length !== 0 &&
      this.props.auth.get('verified')
    ) {
      history.push('/profile');
    }
  }

  render() {
    const { match, location } = this.props;
    return (
      <Layout style={{ background: 'transparent' }}>
        <Content>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ height: 'calc(100vh - 128px)' }}
          >
            <Col style={{ marginLeft: '6%' }} span={6}>
              <Landing />
            </Col>
            <Col style={{ marginRight: '6%' }} span={6}>
              <Route
                path={`${match.url}/forgot/`}
                render={() => (
                  <Forgot auth={this.props.auth} forgot={this.props.forgot} />
                )}
              />
              <Route
                path={`${match.url}/login/`}
                render={() => {
                  return (
                    <Login
                      auth={this.props.auth}
                      login={this.props.login}
                      resetAuthError={this.props.resetAuthError}
                    />
                  );
                }}
              />
              <Route
                path={`${match.url}/reset/`}
                render={() => (
                  <Reset
                    reset={this.props.reset}
                    auth={this.props.auth}
                    location={location}
                  />
                )}
              />
              <Route
                path={`${match.url}/resend/`}
                render={() => (
                  <Resend
                    resend={this.props.resend}
                    auth={this.props.auth}
                    location={location}
                  />
                )}
              />
              <Route
                path={`${match.url}/verify/`}
                render={() => (
                  <Verify
                    verifyEmail={this.props.verifyEmail}
                    auth={this.props.auth}
                    location={location}
                  />
                )}
              />
              <Route
                exact={true}
                path={`${match.url}`}
                render={() => {
                  return (
                    <Signup
                      auth={this.props.auth}
                      resetAuthError={this.props.resetAuthError}
                      signup={this.props.signup}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default Auth;
