import React, { Component } from 'react';
import { Button, Form, Icon, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FormEvent } from 'react';
import * as H from 'history';
import { AuthState } from '@src/redux/modules/auth';
import { Link } from 'react-router-dom';
import { history } from '@src/redux/store';

const FormItem = Form.Item;


interface ResendProps {
  auth: AuthState;
  resend: (email: string) => {};
  location: H.Location;
}


class ResendForm extends Component<ResendProps & FormComponentProps> {
  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.resend(this.props.form.getFieldValue('email'));
      }
    });
  }

  componentWillReceiveProps(nextProps: ResendProps) {
    if (nextProps.auth.get('error')) {
      message.error(nextProps.auth.get('error'));
    }
    if (nextProps.auth.get('message') && this.props.auth.get('message') !== nextProps.auth.get('message')) {
      message.success(nextProps.auth.get('message'));
      history.push('/auth/login');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const loading = this.props.auth.get('loading');
    return (
      <Form onSubmit={this.handleSubmit} className="form--auth form--auth__login">
        <h3 className="text--center">Resend Verification Email</h3>
        <FormItem label="Email">
          {getFieldDecorator('email')(
            <Input
              placeholder="Email"
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="btn--auth" loading={loading}>
            Resend
          </Button>
          <Link to="/auth/login">Sign In</Link>
        </FormItem>
      </Form>
    );
  }
}

const Resend = Form.create<ResendProps>()(ResendForm);


export default Resend;
