import React, { Component, FormEvent } from 'react';
import { Button, Form, Row } from 'antd';
import moment from 'moment';
import { Event, Experience, Profile, Project } from 'src/core/models';
import { EditFormProps } from '@src/core/props';

import '@src/css/EditForm.less';

import {
  AboutFormContainer,
  CertificationFormContainer,
  EducationFormContainer,
  EligibilityFormContainer,
  EventFormContainer,
  ExperienceForm,
  ExperienceFormContainer,
  ProjectFormContainer,
  SkillFormContainer,
} from './index';


const FormItem = Form.Item;


class EditForm extends Component<EditFormProps, {}> {

  private aboutForm: AboutFormContainer;
  private certificationForm: CertificationFormContainer;
  private educationForm: EducationFormContainer;
  private eligibilityForm: EligibilityFormContainer;
  private eventForm: EventFormContainer;
  private experienceForm: ExperienceForm;
  private projectForm: ProjectFormContainer;
  private skillForm: SkillFormContainer;

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();

    const { editType } = this.props;

    if (editType === 'about') {
      this.aboutForm.validateFields((err: Error, values: Profile) => {
        if (!err) {
          this.props.editProfileInfo(values);
        }
      });
    } else if (editType === 'educations') {
      this.educationForm.validateFields((err: Error, values: Profile) => {
        if (!err) {
          let education = values.educations[0];
          education.start_date = moment(education.start_date).format('YYYY-MM-DD');
          education.end_date = moment(education.end_date).format('YYYY-MM-DD');
          if (this.props.modalMode === 'create') {
            this.props.addEducations([education]);
          } else if (this.props.modalMode === 'edit') {
            this.props.editEducation(education);
          }
        }
      });
    } else if (editType === 'certifications') {
      this.certificationForm.validateFields((err: Error, values: Profile) => {
        if (!err) {
          let certification = values.certifications[0];
          certification.start_date = moment(certification.start_date).format('YYYY-MM-DD');
          certification.end_date = moment(certification.end_date).format('YYYY-MM-DD');
          if (this.props.modalMode === 'create') {
            this.props.addCertifications([certification]);
          } else if (this.props.modalMode === 'edit') {
            this.props.editCertification(certification);
          }
        }
      });
    } else if (editType === 'events') {
      this.eventForm.validateFields((err: Error, values: Profile) => {
        if (!err) {
          let events = values.events[0];
          events.event_date = moment(events.event_date).format('YYYY-MM-DD');
          if (this.props.modalMode === 'create') {
            this.props.addEvents([events]);
          } else if (this.props.modalMode === 'edit') {
            this.props.editEvent(events);
          }
        }
      });
    } else if (editType === 'eligibilities') {
      this.eligibilityForm.validateFields((err: Error, values: Profile) => {
        if (!err) {
          delete values.countries;
          this.props.editEligibilities(values);
        }
      });
    } else if (editType === 'experiences') {
      const allFields: string[] = [];
      for (const key of this.experienceForm.props.form.getFieldValue('keys')) {
        allFields.push(`${key}.company_name`);
        allFields.push(`${key}.description`);
        allFields.push(`${key}.from`);
        allFields.push(`${key}.location`);
        allFields.push(`${key}.to`);
        allFields.push(`${key}.title`);
        allFields.push(`${key}.id`);
      }

      this.experienceForm.props.form.validateFields(allFields, (err: Error, values) => {
        if (!err) {
          const experiences: Experience[] = [];
          const projects = this.experienceForm.props.form.getFieldValue(`projectKeys`);

          // Validate start and end date of Experiences
          for (const k of Object.keys(values)) {
            if (values[k].to && values[k].from.valueOf() > values[k].to.valueOf()) {
              return;
            }
            values[k].projects = projects.filter((p: Project) => p.index.indexOf(`form${k}-`) !== -1);
            experiences.push(values[k]);
          }

          allFields.map((k) => {
            if (k.indexOf('.from') !== -1 || k.indexOf('.to') !== -1) {
              const i = k.split('.');
              values[i[0]][i[1]] = values[i[0]][i[1]] ? values[i[0]][i[1]].format('YYYY-MM-DD') : null;
            }
          });
          if (this.props.modalMode === 'create') {
            this.props.addExperiences(experiences);
          } else if (this.props.modalMode === 'edit') {
            this.props.editExperience(experiences[0]);
            if (experiences[0].projects.length > 0) {
              let eId = experiences[0].id;
              experiences[0].projects.forEach((project) => {
                project.experience_id = eId;
              });
              this.props.addProjects(experiences[0].projects);
            }
          }
        }
      });
    } else if (editType === 'project') {
      this.projectForm.validateFields((err: Error, values: Project) => {
        if (!err) {
          if (!values.experience_id) {
            values.experience_id = this.props.modalData.get('id');
          }
          values.from = moment(values.from).format('YYYY-MM-DD');
          if (values.to) {
            values.to = moment(values.to).format('YYYY-MM-DD');
          }
          if (this.props.modalMode === 'create') {
            this.props.addProjects([values]);
          } else if (this.props.modalMode === 'edit') {
            this.props.editProject(values);
          }
        }
      });
    } else if (editType === 'skills') {
      this.skillForm.validateFields((err: Error, values: {}) => {
        if (!err) {
          delete values.skill;

          values.skills.map((skill) => {
            skill.rating = values[`${skill.name.replace(/\s+/g, '-').toLowerCase()}-rating`];
            delete values[`${skill.name.replace(/\s+/g, '-').toLowerCase()}-rating`];
          });

          this.props.addSkills(values.skills);
        }
      });
    }
  }

  handleDelete = () => {
    const { editType, modalData } = this.props;

    if (editType === 'educations') {
      this.props.deleteEducation(modalData.get('id'));
    } else if (editType === 'certifications') {
      this.props.deleteCertification(modalData.get('id'));
    } else if (editType === 'events') {
      this.props.deleteEvent(modalData.get('id'));
    } else if (editType === 'experiences') {
      this.props.deleteExperience(modalData.get('id'));
    } else if (editType === 'project') {
      this.props.deleteProject(modalData as Project);
    }
  }

  render() {
    const { editType, loading, modalData, modalMode } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>

        {editType === 'about' && (
          <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
            <AboutFormContainer about={modalData} ref={(input) => { this.aboutForm = input; }} />
          </div>
        )}

        {editType === 'certifications' && (
          <CertificationFormContainer
            mode={modalMode}
            certification={modalData}
            ref={(input) => { this.certificationForm = input; }}
          />
        )}

        {editType === 'educations' && (
          <EducationFormContainer
            education={modalData}
            mode={modalMode}
            ref={(input) => { this.educationForm = input; }}
          />
        )}

        {editType === 'eligibilities' && (
          <EligibilityFormContainer
            mode={modalMode}
            eligibilities={modalData.toJSON()}
            ref={(input) => { this.eligibilityForm = input; }}
          />
        )}

        {editType === 'events' && (
          <EventFormContainer mode={modalMode} event={modalData} ref={(input) => { this.eventForm = input; }} />
        )}


        {editType === 'experiences' && (
          <div>
            <ExperienceForm
              experience={modalData}
              mode={modalMode}
              wrappedComponentRef={(inst) => this.experienceForm = inst}
            />
          </div>
        )}

        {editType === 'project' && (
          <ProjectFormContainer
            mode={modalMode}
            project={modalData}
            ref={(input) => { this.projectForm = input; }}
          />
        )}

        {editType === 'skills' && (
          <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
            <SkillFormContainer skills={modalData.toJSON()} ref={(input) => { this.skillForm = input; }} />
          </div>
        )}

        <div className="modal-footer">
          <Row type="flex" justify="end">
            <Button type="primary" htmlType="submit" className="btn--submit" loading={loading}>
              Save Changes
            </Button>

            {this.props.isDelete && (
              <Button className="btn--delete" onClick={this.handleDelete}>
                Delete
              </Button>
            )}
          </Row>
        </div>
      </Form>
    );
  }
}


export default Form.create<{}>()(EditForm);
