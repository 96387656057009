import * as Containers from '../views/containers';


export const routes = [
  {
    component: Containers.AuthContainer,
    exact: true,
    path: '/',
  },
  {
    component: Containers.AuthContainer,
    path: '/auth'
  },
  {
    component: Containers.ProfileContainer,
    path: '/profile'
  },
  {
    component: Containers.ResumeContainer,
    exact: true,
    path: '/resume/:username?',
  },
  {
    component: Containers.ResumeContainer,
    exact: true,
    path: '/resume/:username/pvt',
  },
];
