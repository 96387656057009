import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FormEvent } from 'react';

import { AuthState } from '@src/redux/modules/auth';
import { emailValidations, passwordValidations } from '@src/core/validations';


const FormItem = Form.Item;


interface LoginProps {
  auth: AuthState;
  login: (email: string, password: string) => {};
  resetAuthError: () => {};
}


class LoginForm extends Component<LoginProps & FormComponentProps> {

  componentWillReceiveProps(nextProps: LoginProps) {
    if (nextProps.auth.get('error')) {
      message.error(nextProps.auth.get('error'));
      this.props.resetAuthError();
    }
  }

  handleSubmit = (e: FormEvent<{}>) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.login(values.email, values.password);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { auth } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="form--auth form--auth__login">
        <h3 className="text--center">Login to your account</h3>
        <FormItem label="Email Address">
          {getFieldDecorator('email', emailValidations)(
            <Input
              placeholder="Email Address"
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
            />
          )}
        </FormItem>
        <FormItem label="Password">
          <Link to="/auth/forgot" className="btn--forgot">Forgot?</Link>
          {getFieldDecorator('password', passwordValidations)(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="btn--auth" loading={auth.get('loading')}>
            Login
          </Button>
          {/* <Button type="primary" className="btn--auth btn--linkedin">
            <Icon type="linkedin" /> Signin with LinkedIn
          </Button> */}
          <p style={{ margin: '0px' }}>Don't have an account? <Link to="/">Sign Up</Link></p>
          {auth.get('verified') === false &&
            <p style={{ margin: '0px' }}>
              <Link style={{ color: 'red' }} to="/auth/resend">Resend verification email?</Link>
            </p>
          }
        </FormItem>
      </Form>
    );
  }

}

const Login = Form.create<LoginProps>()(LoginForm);


export default Login;
