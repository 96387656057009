import { createAction } from 'redux-actions';
import { List } from 'immutable';

import { Certification, Education, Event, Experience, Profile, Project, Skill } from '@src/core/models';


export const ADD_ACHIEVEMENTS = 'app/profile/achievements/add';

export const ADD_EVENTS = 'app/profile/events/add';
export const ADD_EVENTS_SUCCESS = 'app/profile/events/add/success';

export const ADD_CERTIFICATIONS = 'app/profile/certifications/add';
export const ADD_CERTIFICATIONS_SUCCESS = 'app/profile/certifications/add/success';

export const ADD_PROJECTS = 'app/profile/projects/add';
export const ADD_PROJECTS_SUCCESS = 'app/profile/projects/add/success';

export const ADD_EDUCATIONS = 'app/profile/educations/add';
export const ADD_EDUCATIONS_SUCCESS = 'app/profile/educations/add/success';

export const ADD_EXPERIENCES = 'app/profile/experience/add';
export const ADD_PROFILE_INFO = 'app/profile/info/add';
export const ADD_SKILLS = 'app/profile/skills/add';
export const ADD_SKILL_SUCCESS = 'app/profile/skill/add/success';

export const DELETE_CERTIFICATION = 'app/profile/certification/delete';
export const DELETE_CERTIFICATION_SUCCESS = 'app/profile/certification/delete/success';

export const DELETE_EDUCATION = 'app/profile/education/delete';
export const DELETE_EDUCATION_SUCCESS = 'app/profile/education/delete/success';

export const DELETE_EVENT = 'app/profile/event/delete';
export const DELETE_EVENT_SUCCESS = 'app/profile/event/delete/success';

export const DELETE_EXPERIENCE = 'app/profile/experience/delete';
export const DELETE_EXPERIENCE_SUCCESS = 'app/profile/experience/delete/success';

export const DELETE_PROJECT = 'app/profile/project/delete';
export const DELETE_PROJECT_SUCCESS = 'app/profile/project/delete/success';

export const EDIT_CERTIFICATION = 'app/profile/certification/edit';
export const EDIT_CERTIFICATION_SUCCESS = 'app/profile/certification/edit/success';

export const EDIT_EDUCATION = 'app/profile/education/edit';
export const EDIT_EDUCATION_SUCCESS = 'app/profile/education/edit/success';

export const EDIT_ELIGIBILITIES = 'app/profile/eligibilities/edit';

export const EDIT_EVENT = 'app/profile/event/edit';
export const EDIT_EVENT_SUCCESS = 'app/profile/event/edit/success';

export const EDIT_EXPERIENCE = 'app/profile/experience/edit';
export const EDIT_EXPERIENCE_SUCCESS = 'app/profile/experience/edit/success';

export const EDIT_PROFILE_INFO = 'app/profile/info/edit';

export const EDIT_PROJECT = 'app/profile/project/edit';
export const EDIT_PROJECT_SUCCESS = 'app/profile/project/edit/success';

export const GET_PROFILE = 'app/profile/get';
export const GET_RESUME = 'app/profile/resume/get';

export const PROFILE_ERROR = 'app/profile/error';
export const PROFILE_SUCCESS = 'app/profile/success';

export const RESET_PROFILE = 'app/profile/reset';

export const RESET_PROFILE_ERROR = 'app/profile/error/reset';

export const AddAchievements = createAction(ADD_ACHIEVEMENTS,
  (data: Profile) => (data));

export const AddExperiences = createAction(ADD_EXPERIENCES, (data: List<Experience>) => ({ experiences: data }));

export const AddEvents = createAction(ADD_EVENTS, (data: List<Event>) => ({ events: data }));
export const AddEventsSuccess = createAction(ADD_EVENTS_SUCCESS, (data: List<Event>) => (data));


export const AddCertifications = createAction(ADD_CERTIFICATIONS,
  (data: List<Certification>) => ({ certifications: data }));
export const AddCertificationsSuccess = createAction(ADD_CERTIFICATIONS_SUCCESS, (data: List<Certification>) => (data));


export const AddEducations = createAction(ADD_EDUCATIONS, (data: List<Education>) => ({ educations: data }));
export const AddEducationsSuccess = createAction(ADD_EDUCATIONS_SUCCESS, (data: List<Education>) => (data));

export const AddProjects = createAction(ADD_PROJECTS, (data: List<Project>) => ({ projects: data }));
export const AddProjectsSuccess = createAction(ADD_PROJECTS_SUCCESS, (data: List<Project>) => (data));

export const AddProfileInfo = createAction(ADD_PROFILE_INFO, (data: Profile) => ({ profile: data }));

export const AddSkills = createAction(ADD_SKILLS, (data: List<Skill>) => ({ skills: data }));
export const AddSkillSuccess = createAction(ADD_SKILL_SUCCESS, (data: List<Skill>) => ({ skills: data }));

export const DeleteCertification = createAction(DELETE_CERTIFICATION, (id: number) => (id));

export const DeleteCertificationSuccess = createAction(DELETE_CERTIFICATION_SUCCESS,
  (id: number) => ({ id }));

export const DeleteEducation = createAction(DELETE_EDUCATION, (id: number) => (id));

export const DeleteEducationSuccess = createAction(DELETE_EDUCATION_SUCCESS, (id: number) => ({ id }));

export const DeleteEvent = createAction(DELETE_EVENT, (id: number) => (id));

export const DeleteEventSuccess = createAction(DELETE_EVENT_SUCCESS, (id: number) => ({ id }));

export const DeleteExperience = createAction(DELETE_EXPERIENCE, (id: number) => (id));

export const DeleteExperienceSuccess = createAction(DELETE_EXPERIENCE_SUCCESS, (id: number) => ({ id }));

export const DeleteProject = createAction(DELETE_PROJECT, (data: Project) => (data));

export const DeleteProjectSuccess = createAction(DELETE_PROJECT_SUCCESS, (data: Project) => (data));

export const EditCertification = createAction(EDIT_CERTIFICATION,
  (data: Certification) => ({ certification: data, id: data.id }));

export const EditCertificationSuccess = createAction(EDIT_CERTIFICATION_SUCCESS,
  (data: Certification) => (data));

export const EditEducation = createAction(EDIT_EDUCATION,
  (data: Education) => ({ education: data, id: data.id }));

export const EditEducationSuccess = createAction(EDIT_EDUCATION_SUCCESS,
  (data: Education) => (data));

export const EditEligibilities = createAction(EDIT_ELIGIBILITIES,
  (data: string[]) => (data));

export const EditEvent = createAction(EDIT_EVENT, (data: Event) => ({ event: data, id: data.id }));

export const EditEventSuccess = createAction(EDIT_EVENT_SUCCESS, (data: Event) => (data));

export const EditExperience = createAction(EDIT_EXPERIENCE,
  (data: Experience) => ({ experience: data, id: data.id }));

export const EditExperienceSuccess = createAction(EDIT_EXPERIENCE_SUCCESS,
  (data: Experience) => (data));

export const EditProfileInfo = createAction(EDIT_PROFILE_INFO,
  (data: Profile) => ({ profile: data }));

export const EditProject = createAction(EDIT_PROJECT,
  (data: Project) => ({ project: data, id: data.id }));

export const EditProjectSuccess = createAction(EDIT_PROJECT_SUCCESS,
  (data: Project) => (data));

export const GetProfile = createAction(GET_PROFILE);
export const GetResume = createAction(GET_RESUME, (username: string) => ({ username: username }));

export const ProfileSuccess = createAction(PROFILE_SUCCESS,
  (data: Profile) => (data));

export const ProfileError = createAction(PROFILE_ERROR, (error: Error) => (error));
export const ResetProfileError = createAction(RESET_PROFILE_ERROR, (error: Error) => (error));

export const ResetProfile = createAction(RESET_PROFILE);