import React, { Component } from 'react';
import { Col, DatePicker, Form, Icon, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';

import { Event } from '@src/core/models';
import TextArea from 'antd/lib/input/TextArea';


const FormItem = Form.Item;

let uuid = 1;

interface EventFormProps extends FormComponentProps {
  event: Event;
  mode: string;
}


class EventForm extends Component<EventFormProps> {

  componentDidMount() {
    const { event } = this.props;

    if (event) {
      this.props.form.setFieldsValue({
        events: {
          0: {
            event_date: moment(event.get('event_date')),
            id: event.get('id'),
            institute_name: event.get('institute_name'),
            name: event.get('name'),
            description: event.get('description'),            
          }
        }
      });
    }
  }

  isRequired() {
    const form = this.props.form;
    let fields = form.getFieldsValue();
    let required = false;
    if (fields.events) {
      fields.events.forEach((event, index: number) => {
        if (event.institute_name || event.event_date || event.name || event.description) {
          required = true;
        }
      });
    }

    // if (!required) {
    //   form.resetFields();
    // }

    return required;
  }

  validateCertificationDate(rule: {}, date: Moment, callback: (err?: {}) => {}) {
    const form = this.props.form;
    if (date) {
      if (date.isAfter(moment())) {
        callback(`You can't select a future date`);
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  handleAddEvents = () => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('eventKeys');
    const nextKeys = keys.concat(uuid);
    uuid++;

    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      eventKeys: nextKeys,
    });
  }

  handleRemoveEvents = (k: number) => {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('eventKeys');

    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      eventKeys: keys.filter((key: number) => key !== k),
    });
  }

  render() {
    const { mode } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('eventKeys', { initialValue: [0] });

    const eventKeys = getFieldValue('eventKeys');
    const dynamicRequire = this.isRequired.bind(this)();
    const eventItems = eventKeys.map((k: number, index: number) => {
      return (
        <div
          key={k}
          className={eventKeys.length - 1 === index ? '' : 'achievement--form'}
          style={(index > 0) ? { paddingTop: '20px' } : {}}
        >
          <Row gutter={30}>
            {eventKeys.length > 1 ? (
              <Icon
                onClick={() => this.handleRemoveEvents(k)}
                className="dynamic-delete-button"
                type="minus-circle-o"
              />
            ) : null}
            <Col span={8}>
              <FormItem label="Institute Name" className="form-item" colon={false}>
                {getFieldDecorator(`events.${k}.institute_name`, {
                  rules: [{
                    required: dynamicRequire,
                    message: 'Please enter the institute name',
                    whitespace: true
                  },
                  { min: 3, message: 'Institute Name should be at least 3 characters!' }],
                })(
                  <Input
                    // placeholder="Eg. Schlumberger"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Award/Event/Publication Name" className="form-item" colon={false}>
                {getFieldDecorator(`events.${k}.name`, {
                  rules: [
                    {
                      required: dynamicRequire,
                      message: 'Please enter the Award/Event/Publication name',
                      whitespace: true
                    },
                    { min: 3, message: 'Award/Event/Publication Name should be at least 3 characters!' }
                  ],
                })(
                  <Input
                    // placeholder="Eg. Schlumberger"
                    type="text"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Date of Award/Event/Publication"
                className="form-item"
                colon={false}
              >
                {getFieldDecorator(`events.${k}.event_date`, {
                  rules: [{
                    type: 'object',
                    required: dynamicRequire,
                    message: 'Please select the Date of Certification'
                  },
                  { validator: this.validateCertificationDate.bind(this) },
                  ],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <FormItem label="Description" className="form-item" colon={false}>
              {getFieldDecorator(`events.${k}.description`, {
                rules: [{ required: false, whitespace: true },
                { min: 3, message: 'Description should be at least 3 characters!' }],
              })(
                <TextArea
                  rows={4}
                />
              )}
            </FormItem>
          </Row>

          <FormItem className="form-item hidden">
            {getFieldDecorator(`events.${k}.id`, {})(
              <Input type="text" />
            )}
          </FormItem>
        </div>
      );
    });

    return <div style={{ backgroundColor: 'white', padding: '30px', marginBottom: '15px' }}>
      {mode === 'add' && (
        <h3 className="section--header"> Events, Awards &amp; Publications </h3>
      )}

      {eventItems}

      {mode === 'add' && (
        <Row>
          <h4 onClick={this.handleAddEvents} className="add-experience">+ Add more</h4>
        </Row>
      )}
    </div>;
  }
}

export default Form.create<EventFormProps>()(EventForm);
