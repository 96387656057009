import React, { Component } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import { StepsProps } from '@src/core/props/Steps';

import '@src/css/Steps.less';

import greenAboutIcon from '@src/img/icon/green/user.svg';
import greenWorkIcon from '@src/img/icon/green/work.svg';
import greenAchievmentIcon from '@src/img/icon/green/award.svg';
import blackWorkIcon from '@src/img/icon/black/work.svg';
import blackAchievmentIcon from '@src/img/icon/black/award.svg';

import blueAboutIcon from '@src/img/icon/resume/user.svg';
import blueWorkIcon from '@src/img/icon/resume/work.svg';
import blueAchievmentIcon from '@src/img/icon/resume/awards.svg';

const ToolTip = props => (
  <Tooltip title={props.title}>{props.children}</Tooltip>
);

class Steps extends Component<StepsProps> {
  render() {
    const { location, step } = this.props;
    return (
      <div>
        <Row type="flex">
          <Col
            span={8}
            className={
              'step ' +
              (location.pathname.includes('about') ? 'step--active' : '')
            }
          >
            <ToolTip
              title={
                step === 3 || step === 2
                  ? 'Kindly proceed with the profile filling. You will be able to edit this afer all the steps.'
                  : ''
              }
            >
              <div className="step__item">
                <img
                  src={step === 1 ? `${blueAboutIcon}` : `${greenAboutIcon}`}
                  alt="User icon"
                  className="step__item__icon"
                />
                <div className="step__item__name">
                  <p>Step - 1 </p>
                  <h3>About you</h3>
                </div>
              </div>
            </ToolTip>
          </Col>
          <Col
            span={8}
            className={
              'step ' +
              (location.pathname.includes('work') ? 'step--active' : '')
            }
          >
            <ToolTip
              title={
                step === 3
                  ? 'Kindly proceed with the profile filling. You will be able to edit this afer all the steps.'
                  : ''
              }
            >
              <div className="step__item">
                <img
                  src={
                    step === 2
                      ? `${blueWorkIcon}`
                      : step > 2
                        ? `${greenWorkIcon}`
                        : `${blackWorkIcon}`
                  }
                  alt="Work icon"
                  className="step__item__icon"
                />
                <div className="step__item__name">
                  <p>Step - 2 </p>
                  <h3>Work Experience</h3>
                </div>
              </div>
            </ToolTip>
          </Col>
          <Col
            span={8}
            className={
              'step ' +
              (location.pathname.includes('achievements') ? 'step--active' : '')
            }
          >
            <div className="step__item">
              <img
                src={
                  step === 3
                    ? `${blueAchievmentIcon}`
                    : step > 2
                      ? `${greenAchievmentIcon}`
                      : `${blackAchievmentIcon}`
                }
                alt="Achievments icon"
                className="step__item__icon"
              />
              <div className="step__item__name">
                <p>Step - 3 </p>
                <h3>Achievements</h3>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Steps;
